import React from 'react';
import { Button, Tooltip } from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';

const UndoRedoButtons = ({ handleUndo, handleRedo }) => {
  return (
    <div className="undo-container">
    <Tooltip title="Undo">
      <Button
        className="icon-button"
        variant="contained"
        color="secondary"
        onClick={handleUndo}
      >
        <UndoIcon />
      </Button>
    </Tooltip>
    <Tooltip title="Redo">
      <Button
        className="icon-button"
        variant="contained"
        color="secondary"
        onClick={handleRedo}
      >
        <RedoIcon />
      </Button>
    </Tooltip>
  </div>
  );
};

export default UndoRedoButtons;
