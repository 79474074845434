const sidebarShowInitialState = true

const sidebarShowReducer = (state = sidebarShowInitialState, action) => {
  if (action.type === 'TOGGLE_SIDEBAR') {
    return !state
  }
  return state
}

export default sidebarShowReducer
