import { SET_ARCHITECTUTE_EDITOR_VALUE } from "../actions/ArchEditorActions";

const localStorageKey = 'EDITORSTATE';

const storedArchitectureEditorValue = localStorage.getItem(localStorageKey);
const defaultArchitectureEditorValue =
storedArchitectureEditorValue ||
"#Node is an abstract concept that represents a single system component object. In below example Team is a node\nTeams(\"Teams\")\n\n#Bundle allows you group (or clustering) the nodes in an isolated group. In below example ELB and EC2 are bundled.\nBundle(\"DB Cluster\"): ELB(\"lb\") >> EC2(\"web\")\n\n#Edge is an object representing a connection between Nodes with some additional properties. In below example >> are edge.\nawsUser(\"User\") >> Teams(\"Teams\")";
const initialState = {
  architectureEditorValue: storedArchitectureEditorValue || defaultArchitectureEditorValue,
};

const architectureEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ARCHITECTUTE_EDITOR_VALUE:
      return {
        ...state,
        architectureEditorValue: action.payload,
      };
    default:
      return state;
  }
};

export default architectureEditorReducer;
