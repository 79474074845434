import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { COLORS } from 'src/components/Colors'
import { Button, CircularProgress } from '@mui/material'

function PricingPage() {
  const navigate = useNavigate()
  const [showSpinner, setShowSpinner] = useState(true)
  const [pricingTable, setPricingTable] = useState('prctbl_1O7BY0SIwDDSGctw66Dj1cUt')
  const [publishKey, setPublishKey] = useState(
    'pk_live_51NTgUeSIwDDSGctwpSpFDngdypJepykhxsIGefdiT1CC0XPPl6K9f6n47JOMHUbvasmIbOaPTGOeNQAHBmxEDtHl002gHZcrac',
  )

  var userObjStr = sessionStorage.getItem('userFetched')
  var userObj = JSON.parse(userObjStr)
  const CUSTOMER_EMAIL = userObj.email
  var CLIENT_REFERENCE_ID = userObj.userId
  CLIENT_REFERENCE_ID = CLIENT_REFERENCE_ID.replace('|', '_')

  useEffect(() => {
    function stopSpinner() {
      setShowSpinner(false)
    }
    if (process.env.REACT_APP_SIDEBAR_API_URL === 'https://test.code2diagram.com') {
      setPricingTable('prctbl_1PIsMbSJfluo37bJeBcePR2h')
      setPublishKey(
        'pk_test_51PIrqbSJfluo37bJEapwmogpRPtB6lLV2Skw0YCLt47rVbiWwXtOca2SxPGNsKyajxydBLBfitFdbhBVJEGOnANZ004cObqcRN',
      )
    } else if (process.env.REACT_APP_SIDEBAR_API_URL === 'http://localhost:8080') {
      setPricingTable('prctbl_1PIsMbSJfluo37bJeBcePR2h')
      setPublishKey(
        'pk_test_51PIrqbSJfluo37bJEapwmogpRPtB6lLV2Skw0YCLt47rVbiWwXtOca2SxPGNsKyajxydBLBfitFdbhBVJEGOnANZ004cObqcRN',
      )
    }
    setTimeout(stopSpinner, 1000)
  }, [])

  return (
    <div>
      {showSpinner && (
        <div className="d-flex justify-content-center" style={{ paddingTop: '100px' }}>
          <CircularProgress />
        </div>
      )}

      <br />
      {!showSpinner && (
        <Button
          style={{ backgroundColor: COLORS.PRIMARY_DARK }}
          size="medium"
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon fontSize="small" />
          Back
        </Button>
      )}

      <br />
      <br />
      {!showSpinner && (
        <stripe-pricing-table
          pricing-table-id={pricingTable}
          publishable-key={publishKey}
          customer-email={CUSTOMER_EMAIL}
          client-reference-id={CLIENT_REFERENCE_ID}
        ></stripe-pricing-table>
      )}
    </div>
  )
}

export default PricingPage
