import { SET_PROTOBUF_EDITOR_VALUE } from '../actions/protobufEditorAction'

const localStorageKey = 'protobuf_diagram'

const storedProtobufEditorValue = localStorage.getItem(localStorageKey)

const defaultProtobufEditorValue = storedProtobufEditorValue || 'message Person {\n string name = 1;\n int32 id = 2; \n string email = 3;\n }'

const initialState = {
  protobufEditorValue: storedProtobufEditorValue || defaultProtobufEditorValue,
}

const protobufEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROTOBUF_EDITOR_VALUE:
      return {
        ...state,

        protobufEditorValue: action.payload,
      }

    default:
      return state
  }
}

export default protobufEditorReducer
