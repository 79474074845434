export const SET_SELECTED_MODE_VALUE = "SET_SELECTED_MODE";

export const setSelectedMode = (selectedModeEditorValue) => ({
  type: SET_SELECTED_MODE_VALUE,
  payload: selectedModeEditorValue,
});

export const SET_RUN_TOUR = "SET_RUN_TOUR";

export const setRunTour = (runTourValue) => ({
  type: SET_RUN_TOUR,
  payload: runTourValue,
});