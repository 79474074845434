import { SET_EDITOR_VALUE } from '../actions/editorActions';

const localStorageKey = 'flowchart_diagram';

const storedEditorValue = localStorage.getItem(localStorageKey);
const defaultEditorValue =
  storedEditorValue ||
  'Start - Decision \n Decision -  Process1 : yes \n Decision - Process2 : no \n Process1 - Process3 \n Process3 - Process4 : Yes \n Process3 - Process5 : No \n Process4 - End \n Process5 - End'
const initialState = {
  editorValue: storedEditorValue || defaultEditorValue,
};

const editorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EDITOR_VALUE:
      return {
        ...state,
        editorValue: action.payload,
      };
    default:
      return state;
  }
};

export default editorReducer;
