import { Snackbar } from '@mui/material'
import React from 'react'

const CompileAlert = ({
  openSnackbarDiagram,
  handleSnackbarCloseDiagram,
}) => {
  return (
    <Snackbar
        open={openSnackbarDiagram}
        message="Compiling..."
        onClose={() => handleSnackbarCloseDiagram(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
  )
}

export default CompileAlert
