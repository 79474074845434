import React from 'react'
import { Box, Modal, Typography } from '@mui/material'
import { modalStyle } from 'src/utils/history'

const PaymentModal = ({ showPaymentConfirm, setShowPaymentConfirm }) => {
  return (
    <Modal open={showPaymentConfirm} onClose={() => setShowPaymentConfirm(false)}>
      <Box sx={modalStyle}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Payment Confirmation
        </Typography>
        <hr />
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <h5>You have successfully subscribed</h5>
        </Typography>
      </Box>
    </Modal>
  )
}

export default PaymentModal
