import React, { useState } from 'react'
import { Tooltip } from '@mui/material'
import { FaSearchPlus, FaSearchMinus } from 'react-icons/fa'

const ZoomButtons = ({ handleZoomIn, handleZoomOut }) => {
  const iconSize = 15
  const [theme, setTheme] = useState('light')

  return (
    <div className="zoom-div">
      <Tooltip title="Zoom In">
        <button onClick={handleZoomIn} className="zoom-button">
          <FaSearchPlus
            style={{ fontSize: iconSize, color: theme === 'dark' ? 'white' : '#183d3d' }}
          />
        </button>
      </Tooltip>

      <Tooltip title="Zoom Out">
        <button onClick={handleZoomOut} className="zoom-button">
          <FaSearchMinus
            style={{ fontSize: iconSize, color: theme === 'dark' ? 'white' : '#183d3d' }}
          />
        </button>
      </Tooltip>
    </div>
  )
}

export default ZoomButtons
