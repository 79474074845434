import { SET_AVROSCHEMA_EDITOR_VALUE, SET_AVROSCHEMA_FILE_VALUE } from '../actions/avroSchemaAction'

const localStorageKey = 'avroSchema_diagram'

const storedAvroSchemaEditorValue = localStorage.getItem(localStorageKey)

const defaultAvroSchemaEditorValue =
  storedAvroSchemaEditorValue ||
  "{ \n \"type\": \"record\",\n \"name\": \"User\",\n \"fields\": [\n {\"name\": \"id\", \"type\": \"int\"},\n {\"name\": \"username\", \"type\": \"string\"},\n {\"name\": \"email\", \"type\": \"string\"}\n ]\n}"

const initialState = {
  avroSchemaEditorValue: storedAvroSchemaEditorValue || defaultAvroSchemaEditorValue,
}

const avroSchemaReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AVROSCHEMA_EDITOR_VALUE:
      return {
        ...state,

        avroSchemaEditorValue: action.payload,
      }

    case SET_AVROSCHEMA_FILE_VALUE:
      return {
        ...state,

        avroSchemaFileValue: action.payload,
      }

    default:
      return state
  }
}

export default avroSchemaReducer
