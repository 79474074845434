import { SET_BRAINSTORM_EDITOR_VALUE } from '../actions/brainstormEditorAction'
import { SET_D2_EDITOR_VALUE } from '../actions/brainstormEditorAction'
import { SET_BRAINSTORM_IMAGE_VALUE } from '../actions/brainstormEditorAction'

const localStorageKey = 'brainstorm_diagram'
const storedBrainstormEditorValue = localStorage.getItem(localStorageKey)
const defaultBrainstormEditorValue = storedBrainstormEditorValue || 'a\n    b\n        g1:"g"\n    c\n        d\n        e\n       b -> e\n g1 -> d'

const localStorageKeyForD2 = 'd2_diagram'
const storedD2EditorValue = localStorage.getItem(localStorageKeyForD2)
const defaultD2EditorValue = storedD2EditorValue || 'vars: {\n  d2-config: {\n    layout-engine: elk\n    # Terminal theme code\n    theme-id: 300\n  }\n}\nnetwork: {\n  cell tower: {\n    satellites: {\n      shape: stored_data\n      style.multiple: true\n    }\n\n    transmitter\n\n    satellites -> transmitter: send\n    satellites -> transmitter: send\n    satellites -> transmitter: send\n  }\n\n  online portal: {\n    ui: {shape: hexagon}\n  }\n\n  data processor: {\n    storage: {\n      shape: cylinder\n      style.multiple: true\n    }\n  }\n\n  cell tower.transmitter -> data processor.storage: phone logs\n}\n\nuser: {\n  shape: person\n  width: 130\n}\n\nuser -> network.cell tower: make call\nuser -> network.online portal.ui: access {\n  style.stroke-dash: 3\n}\n\napi server -> network.online portal.ui: display\napi server -> logs: persist\nlogs: {shape: page; style.multiple: true}\n\nnetwork.data processor -> api server'



const localStorageForImageKey = 'brainstorm_image_diagram'
const storedBrainstormImageValue = localStorage.getItem(localStorageForImageKey)

const initialState = {
  brainstormEditorValue: storedBrainstormEditorValue || defaultBrainstormEditorValue,
  d2EditorValue: storedD2EditorValue || defaultD2EditorValue,
  brainstormImageValue: storedBrainstormImageValue,
}

const brainstormEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BRAINSTORM_EDITOR_VALUE:
      return {
        ...state,
        brainstormEditorValue: action.payload,
      }
      case SET_D2_EDITOR_VALUE:
      return {
        ...state,
        d2EditorValue: action.payload,
      }
    case SET_BRAINSTORM_IMAGE_VALUE:
      return {
        ...state,
        brainstormImageValue: action.payload,
      }
    default:
      return state
  }
}

export default brainstormEditorReducer
