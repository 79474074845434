import { Box, Button, Modal, TextField, Typography } from '@mui/material'
import React from 'react'
import { modalStyle } from 'src/utils/history'

const DownloadModal = ({
  showPopup,
  setShowPopup,
  diagramName,
  setDiagramName,
  handleDownloadCnst,
}) => {
  return (
    <Modal open={showPopup} onClose={() => setShowPopup(false)}>
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Diagram name
        </Typography>
        <hr />
        <TextField
          size="small"
          hiddenLabel
          id="filled-hidden-label-small"
          placeholder="Enter Diagram Name"
          autoComplete="off"
          value={diagramName}
          onChange={(e) => setDiagramName(e.target.value)}
        />
        <footer style={{ display: 'flex', gap: '70px', justifyContent: 'flex-end', margin: '15px'}}>
          <Button size="small" className="cancel-button" onClick={() => setShowPopup(false)}>
            Cancel
          </Button>
          <Button
            size="small"
            className="icon-button"
            onClick={() => {
              handleDownloadCnst()
              setShowPopup(false)
            }}
          >
            Download
          </Button>
        </footer>
      </Box>
    </Modal>
  )
}

export default DownloadModal
