import React, { useState, useEffect } from "react";
import { Box, Button, Modal, Tooltip, TextField, InputAdornment } from '@mui/material';
import axios from "axios";
import { CONSTANTS } from '../Constants';
import { useSelector } from "react-redux";
import vectorIcon from "src/assets/Vector.png";
import extentionIcon from "src/assets/Extension.png"
import HdrIcon from "src/assets/Hdr weak.png"
import autoIcon from "src/assets/Auto fix high.png"
import { ReactComponent as AiIcon } from 'src/assets/Aicon.svg';
import { ReactComponent as AiIc } from 'src/assets/ai (1).svg';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SendIcon from '@mui/icons-material/Send';
import Logo from 'src/assets/c2d_original_v2_with_text.png'
import Alert from '@mui/material/Alert';
import { useLocation, useNavigate } from 'react-router-dom';


const ChatGPT = ({ onResponse,  isAuthenticated, setSnackbarSeverity, setOpenSnackbar, setSnackbarMessage }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [loading, setLoading] = useState(false);
    const selectedMode = useSelector((state) => state.stateReducer.selectModeValue);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (location.pathname === '/ai') {
            setOpen(true);
        }
    }, [location.pathname]);
    
    const handleClose = () => {
        setOpen(false);
        navigate("/console")
        setInputValue("");
        setLoading(false);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setErrorMessage('');
    };
    


    const sendRequest = (promptText) => {
        setLoading(true);
        const reqBody = {
            prompt: promptText
        };
        let apiUrl = '';

        if (selectedMode === CONSTANTS.FLOW_CHART) {
            apiUrl = `${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/c2d/gpt/flowchart/generate?isDownload=false`;
        } else if (selectedMode === CONSTANTS.WEBSEQUENCE) {
            apiUrl = `${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/c2d/gpt/webseq/generate?isDownload=false`;
        }

        if (apiUrl) {
            axios.post(apiUrl, reqBody, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (response.data.result) {
                        const code = response.data.result[1];
                        console.log("Generated Code:", code);
                        localStorage.setItem("chatGPTCode", code);
                        //onResponse(code);
                        navigate("/console")
                        onResponse(code);
                    }
                    setLoading(false);
                    handleClose();
                })
                .catch(e => {
                    console.log("Error:", e);
                    setLoading(false);
                    handleClose();
                });
        } else {
            setLoading(false);
            handleClose();
        }
    };

    const handleBoxClick = (promptText) => {
        sendRequest(promptText);
    };

    const handleSuccessClick = () => {
        if (inputValue) {
            sendRequest(inputValue);
        }
        else if (inputValue.trim() === '')
            setErrorMessage('Please enter a prompt');
        return;
    };
    useEffect(() => {
        if (errorMessage) {
            const timer = setTimeout(() => {
                setErrorMessage('');
            }, 3000); // 3 seconds

            return () => clearTimeout(timer);
        }
    }, [errorMessage]);




    const isAIAvailable = selectedMode === CONSTANTS.FLOW_CHART || selectedMode === CONSTANTS.WEBSEQUENCE;

    const handleOpenInNewTab = () => {
        if (!isAuthenticated) {
            setSnackbarMessage('Please login')
            setSnackbarSeverity('warning')
            setOpenSnackbar(true)
            return
          }

        if (isAIAvailable) {
         
          const baseUrl = window.location.origin; 
          window.open(`${baseUrl}/ai`, '_blank'); 
        }
      };

    return (
        <div>

<Tooltip title={isAIAvailable ? "AI button" : "AI is currently available for Flowchart and WebSequence only"}>
                <span>
                    <Button
                        className="icon-button ai-button"
                        variant="contained"
                        onClick={isAIAvailable ? handleOpenInNewTab : undefined}
                        // onClick={isAIAvailable ? handleOpen : undefined}
                        disabled={!isAIAvailable}
                        sx={{
                            cursor: isAIAvailable ? 'pointer' : 'not-allowed',
                            '&:hover': {
                                backgroundColor: isAIAvailable ? 'primary.main' : 'grey.400',
                            },
                        }}
                    >
                        <AiIc style={{ height: '25px', width: '25px' }} />
                    </Button>
                </span>
            </Tooltip>

            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box
                    sx={{
                        position: 'relative',
                        width: '100%',
                        height: '100vh',
                        backgroundImage: `linear-gradient(to top, #000, #183d3d)`,
                        backgroundColor: 'black',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        overflow: 'auto',
                        padding: '16px',

                    }}
                >


                    <HighlightOffIcon
                        sx={{
                            position: 'absolute',
                            right: 16,
                            top: 16,
                            color: 'white',
                            cursor: 'pointer',
                            transition: 'color 0.3s, transform 0.3s',
                            '&:hover': {

                                transform: 'scale(1.2)',
                            },
                        }}
                        onClick={handleClose}
                    />


                    <div className="ai_topic">
                        <h1 className="project">
                            <img src={Logo} className="logo_Landing" alt="logo" height={20} /><br />

                            Create Code and Diagrams Instantly with AI

                        </h1>
                    </div>


                    {selectedMode === CONSTANTS.FLOW_CHART ? (
                        <div className="container">
                            <div className="box" onClick={() => handleBoxClick("Generate a detailed flowchart for a restaurant food preparation workflow including multiple decision points, parallel processes, and iterativ loops.")}>
                                <img className="iconai" src={vectorIcon} alt="Generate a detailed flowchart for a restaurant food preparation workflow including multiple decision points, parallel processes, and iterativ loops." />
                                <p style={{ padding: '16px 16px 16px 0', textAlign: 'justify' }}>Generate a detailed flowchart for a restaurant food preparation workflow including multiple decision points, parallel processes, and iterativ loops.</p>
                            </div>
                            <div className="box" onClick={() => handleBoxClick("Generate a detailed flowchart for a environmental impact assessment including multiple decision points, parallel processes, and iterative loops.")}>
                                <img className="iconai" src={extentionIcon} alt="Generate a detailed flowchart for a environmental impact assessment including multiple decision points, parallel processes, and iterative loops." />
                                <p style={{ padding: '16px 16px 16px 0', textAlign: 'justify' }}>Generate a detailed flowchart for a environmental impact assessment including multiple decision points, parallel processes, and iterative loops.</p>
                            </div>
                            <div className="box" onClick={() => handleBoxClick("Generate a detailed flowchart for a business merger and acquisition including multiple decision points, parallel processes, and iterative loops.")}>
                                <img className="iconai" src={HdrIcon} alt="Generate a detailed flowchart for a business merger and acquisition including multiple decision points, parallel processes, and iterative loops." />
                                <p style={{ padding: '16px 16px 16px 0', textAlign: 'justify' }}>
                                    Generate a detailed flowchart for a business merger and acquisition including multiple decision points, parallel processes, and iterative loops</p>
                            </div>
                            <div className="box" onClick={() => handleBoxClick("Generate a detailed flowchart for a data analysis pipeline including multiple decision points, parallel processes, and iterative loops.")}>
                                <img className="iconai" src={autoIcon} alt="Generate a detailed flowchart for a data analysis pipeline including multiple decision points, parallel processes, and iterative loops." />
                                <p style={{ padding: '16px 16px 16px 0', textAlign: 'justify' }}>
                                    Generate a detailed flowchart for a data analysis pipeline including multiple decision points, parallel processes, and iterative loops
                                </p>
                            </div>
                        </div>

                    ) : selectedMode === CONSTANTS.WEBSEQUENCE ? (
                        <div className="container">
                            <div className="box" onClick={() => handleBoxClick("Diagram of a virtual reality (VR) ecosystem, including VR devices, content delivery, and user interaction analytics.")}>
                                <img src={vectorIcon} alt="Diagram of a virtual reality (VR) ecosystem, including VR devices, content delivery, and user interaction analytics." />
                                <p style={{ padding: '16px 16px 16px 0', textAlign: 'justify' }}>Diagram of a virtual reality (VR) ecosystem, including VR devices, content delivery, and user interaction analytics.</p>
                            </div>
                            <div className="box" onClick={() => handleBoxClick("Diagram of a zero-trust security model, illustrating access controls, authentication, and continuous monitoring.")}>
                                <img src={extentionIcon} alt="Diagram of a zero-trust security model, illustrating access controls, authentication, and continuous monitoring." />

                                <p style={{ padding: '16px 16px 16px 0', textAlign: 'justify' }}>Diagram of a zero-trust security model, illustrating access controls, authentication, and continuous monitoring.</p>
                            </div>
                            <div className="box" onClick={() => handleBoxClick("Diagram representing a complex API integration between multiple services, including authentication, data transformation, and error handling.")}>
                                <img src={HdrIcon} alt="Diagram representing a complex API integration between multiple services, including authentication, data transformation, and error handling." />
                                <p style={{ padding: '16px 16px 16px 0', textAlign: 'justify' }}>Diagram representing a complex API integration between multiple services, including authentication, data transformation, and error handling.</p>
                            </div>
                            <div className="box" onClick={() => handleBoxClick("Diagram showing the process flow of a machine learning model lifecycle, including data collection, model training, evaluation, and deployment.")}>
                                <img src={autoIcon} alt="Diagram showing the process flow of a machine learning model lifecycle, including data collection, model training, evaluation, and deployment." />
                                <p style={{ padding: '16px 16px 16px 0', textAlign: 'justify' }}>Diagram showing the process flow of a machine learning model lifecycle, including data collection, model training, evaluation, and deployment.</p>
                            </div>
                        </div>
                    ) : null}
                    
                   
                    <div
                        className="input-container">

                        <TextField
                            fullWidth

                            placeholder="Enter your Prompt"
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    if (inputValue.trim() === '') {
                                        event.preventDefault();
                                        setErrorMessage('Please enter a prompt');
                                    } else {
                                        handleSuccessClick();
                                    }
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SendIcon
                                            onClick={handleSuccessClick}
                                            sx={{
                                                color: '#fff',
                                                height: '32px',
                                                width: '32px',

                                                transition: 'color 0.3s, transform 0.3s',
                                                marginBottom: '5px',
                                                '&:hover': {
                                                    transform: 'scale(1.2)',
                                                    cursor: 'pointer',
                                                },
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            multiline
                            minRows={1}
                            maxRows={2}
                        />
                    </div>

                    <div >
                        {errorMessage && (
                            <Alert
                                sx={{ bgcolor: 'background.paper' }}
                                severity="error"

                            >
                                {errorMessage}
                            </Alert>
                        )}

                        {loading && (
                            <Box className="loading-box">
                                <Alert sx={{ bgcolor: 'background.paper' }}>
                                    Generating the code...
                                </Alert>
                            </Box>
                        )}
                    </div>
             
                </Box>
            </Modal>
        </div>
    );
}

export default ChatGPT;