import React, { useEffect, useState} from 'react';
import { MapContainer } from 'react-leaflet';
import { PanZoom } from 'react-easy-panzoom';

function LeafletMap({ imageSrc, imageWidth, imageHeight, panZoomRef }) {
  const [imageLoaded, setImageLoaded] = useState(true);

  useEffect(() => {
    if (panZoomRef.current) {
      panZoomRef.current.setOptions({ preventScroll: true });
    }
  }, [panZoomRef]);
  const handleImageError = () => {
    setImageLoaded(false);
  };

  useEffect(()=>{
    setImageLoaded(true);
  },[imageSrc])
  
  return (
    <div className="alignMiddle" style={{ overflow: 'auto' }}>
    <MapContainer center={[51.505, -0.09]} zoom={13} attributionControl={false} zoomControl={false}>
      <div className="alignMiddle">
        <PanZoom ref={panZoomRef}>
          {imageLoaded ? (
            <img
              className="diagram-output image-style"
              src={imageSrc}
              alt="My Diagram"
              width={imageWidth}
              height={imageHeight}
              onError={handleImageError}
            />
          ) : (
            <div style={{ color: 'gray', fontSize: '24px' }}></div>
            
          )}
        </PanZoom>
      </div>
    </MapContainer>
  </div>   
  );
}

export default LeafletMap;