import React, { useState, useEffect } from 'react'
import './scss/Landing.css'
import CodeRoundedIcon from '@mui/icons-material/CodeRounded'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import AllInclusiveRoundedIcon from '@mui/icons-material/AllInclusiveRounded'
import Groups3RoundedIcon from '@mui/icons-material/Groups3Rounded'
import DynamicFormRoundedIcon from '@mui/icons-material/DynamicFormRounded'
import BuildCircleRoundedIcon from '@mui/icons-material/BuildCircleRounded'
import { Link } from 'react-router-dom'
import Logo from './assets/c2d_original_v2_with_text.png'

import SocialMediaShare from './components/SocialMediaShare'
// import OudatedBrowser from './components/modals/OudatedBrowser'
import IncompatibleDevice from './components/modals/IncompatibleDevice'
import MobileWarningModal from './MobileWarningModal';
import OudatedBrowser from './OudatedBrowser';
import mermaid from 'mermaid'


const Landing = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [isSafariOutdated, setIsSafariOutdated] = useState(false)
  const [showIncompatibleDevice, setShowIncompatibleDevice] = useState(false)
  const [showOutdatedBrowser, setShowOutdatedBrowser] = useState(false)
  const [showMobileWarning, setShowMobileWarning] = useState(false);

  function initMermaid(){
    mermaid.initialize({
      theme: 'base',
      themeVariables: {
        primaryColor: '#bccfc7',
        primaryTextColor: '#666666',
        primaryBorderColor: '#183D3D',
        lineColor: '#F8B229',
        edgeLabelBackground: 'transparent',
      },
    })
    var classDiagram= "classDiagram \n direction TB \nclass Animal \nVehicle <|-- Car"
    mermaid.render('graph', classDiagram)
    var flowchart = "flowchart TB\nStart([Start ]):::terminal -->Decision[Decision]\nclassDef terminal fill: pink\nclassDef decision fill: light-blue"
    mermaid.render('graph', flowchart)
  }

  useEffect(() => {
    initMermaid()
    const windowWidth = window.innerWidth
    if (windowWidth < 768) {
      setIsMobile(true)
    }

    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      const safariVersion = navigator.userAgent.match(/Version\/(\d+\.\d+)/)

      if (safariVersion) {
        const safariVersionFloat = parseFloat(safariVersion[1])
        if (safariVersionFloat < 17) setIsSafariOutdated(true)
      } else {
        console.log('Unable to determine Safari version.')
      }
    }
  }, [])

  const handleClick = () => {
    if (isMobile) {
      setShowMobileWarning(true); 
    } else if (isSafariOutdated) {
      setShowOutdatedBrowser(true)
    }
  }

  return (
    <>
      <Link to="/">
        <img src={Logo} className="logo_Landing" alt="logo" height={20} />
      </Link>
      <main className="landing_main">
        <div className="landing_first_container">
          <div className="main_video">
            <video controls autoPlay muted loop>
              <source
                src="https://res.cloudinary.com/dpqbidfvh/video/upload/v1712648713/achri_diagram_video_zowvr3.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="landing_text">
            <div className="landing_header">Hey!...Create jaw dropping diagrams at 10x speed</div>
            <p className="landing_header_text">
              Discover the forefront of innovation and join the industry leader in embracing our
              reliable, revolutionary product, redefining the experience.
            </p>
            <Link
              className="tryButton"
              onClick={handleClick}
              to={isMobile || isSafariOutdated ? '' : '/console'}
            >
              <span>Try Now for Free &rarr;</span>
            </Link>
          </div>
        </div>
        <div className="landing_second_container">
          <div className="second_container_content">
            <div className="landing_subcontent">
              <div className="subcontent_header"> Web Sequence</div>
              Accelerate your web sequence diagrams 10x faster with Code 2 Diagram, Effortlessly
              visualize web interactions with unprecedented speed.
            </div>
            <div className="main_video ">
              <video className="subcontent_video" controls autoPlay muted loop>
                <source
                  src="https://res.cloudinary.com/dpqbidfvh/video/upload/v1709102826/WhatsApp_Video_2024-02-27_at_3.23.50_PM_y2omjv.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <hr/>
          <div className="second_container_content">
            <div className="main_video ">
              <video className="subcontent_video" controls autoPlay muted loop>
                <source
                  src="https://res.cloudinary.com/dpqbidfvh/video/upload/v1712649356/Copy_of_C2D_flow_chart_Video_z0z5uq.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="landing_subcontent">
              <div className="subcontent_header"> Flow Chart</div>
              Unlock the power of visualization with our meticulously crafted flow chart, guiding
              you seamlessly through complex processes with clarity and precision.
            </div>
          </div>
        </div>
        <div className="landing_third_container">
          <h2>Streamline Your Workflow with Our Dynamic Features</h2>
          <div className="third_container_div">
            <div>
              <h5 className="feature_div">
                <CodeRoundedIcon /> Code-Powered Blueprints
              </h5>
              <h5 className="feature_div">
                <DownloadRoundedIcon /> Seamless Integration, Effortless Downloads
              </h5>
              <h5 className="feature_div">
                <AllInclusiveRoundedIcon /> Infinite Icon Library
              </h5>
            </div>
            <div>
              <h5 className="feature_div">
                <DynamicFormRoundedIcon />
                Intuitive Code Generation
              </h5>
              <h5 className="feature_div">
                <Groups3RoundedIcon /> Collaborative Powerhouse
              </h5>
              <h5 className="feature_div">
                <BuildCircleRoundedIcon /> Customization Made Simple
              </h5>
            </div>
          </div>
        </div>
        <div className="landing_second_container">
          <h2 className="diagram_list_header">List of our Diagrams</h2>
          <div className="diagram_list">
            <div>
              <div>Architecture Diagram</div>
              <div>Web Sequence Diagram</div>
              <div>FlowChart</div>
              <div>JSON to UML</div>
              <div>YAML to UML</div>
              <div>Swagger to UML</div>
              <div>Protobuf to UML</div>
              <div>Class Diagram</div>
              <div>Inventory Grapher</div>
            </div>
            <div>
              <div>BrainStorming</div>
              <div>Avro-Schema</div>
              <div>Docker-compose</div>
              <div>D2 Diagram</div>
              <div>Playbook Grapher</div>
              <div>MindMap</div>
              <div>Terraform</div>
              <div>Math equation</div>
              <div>
                Gantt Chart
                <span className="coming_soon">&nbsp;coming soon&nbsp;</span>
              </div>
            </div>
          </div>
        </div>
        <div className="partners_list_container">
          <h2>Trusted by leading engineering teams globally</h2>
          <div className="partners_list">
            <img src="https://pngimg.com/uploads/ibm/ibm_PNG19649.png" width={75} height={75} />
            <img
              src="https://companieslogo.com/img/orig/ADNOCDIST.AE_BIG.D-21452461.png?t=1673160675png"
              width={75}
              height={75}
            />
            <img
              src="https://companieslogo.com/img/orig/CAP.PA_BIG.D-679c041e.png"
              width={90}
              height={90}
            />
            <img
              src="https://companieslogo.com/img/orig/CTSH_BIG.D-6e2ffe6b.png?t=1652276339"
              width={90}
              height={90}
            />
            <img
              src="https://train.riadatabase.com/digitalassets/wp-content/uploads/sites/5/2022/01/Nasdaq-Logo-White.png"
              width={75}
              height={75}
            />
            <img
              src="https://www.pikpng.com/pngl/b/567-5678580_logo-scania-png-scania-logo-white-clipart.png"
              width={65}
              height={65}
            />
          </div>
        </div>
        <hr/>
        <div className="landing_footer">
          <span className="landing_footer_text">All Rights Reserved &copy; 2024</span>
          <SocialMediaShare />
        </div>
      </main>

      <OudatedBrowser
        showOutdatedBrowser={showOutdatedBrowser}
        setShowOutdatedBrowser={setShowOutdatedBrowser}
      />
      <IncompatibleDevice
        showIncompatibleDevice={showIncompatibleDevice}
        setShowIncompatibleDevice={setShowIncompatibleDevice}
      />
      <MobileWarningModal open={showMobileWarning} onClose={() => setShowMobileWarning(false)} /> {/* Add the mobile warning modal */}
    </>
  )
}

export default Landing
