import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const OudatedBrowser = ({ showOutdatedBrowser, setShowOutdatedBrowser }) => {
  const handleClose = () => {
    setShowOutdatedBrowser(false);
  };

  return (
    <Modal open={showOutdatedBrowser} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2">
        Welcome to Code 2 Diagram
        </Typography>
        <Typography sx={{ mt: 2 }}>
          For the best experience, we recommend using our platform on a desktop or with a larger screen.
          Mobile devices may not offer the optimal usability and functionality our tools provide.
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Thank you for your understanding. Happy coding!
        </Typography>
        <Button variant="contained" onClick={handleClose} sx={{ mt: 2 }}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default OudatedBrowser;
