import React, { useEffect, useState, useRef } from 'react';
import functionPlot from 'function-plot';
import YAML from 'yaml';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';

const MathEquation = ({ setOpenSnackbar, setSnackbarMessage, setSnackbarSeverity }) => {
  const mathEqEditorValue = useSelector((state) => state.mathEqEditor.mathEqEditorValue);
  const [initial, setInitial] = useState(true);
  const [loading, setLoading] = useState(true);
  const diagramRef = useRef(null);

  const downloadChartSVG = () => {
    const diagramSVG = diagramRef.current.innerHTML;

    let watermark = '';

    // Check subscription status and add watermark if not subscribed
    if (sessionStorage.getItem('isSubscribed') !== 'true') {
        watermark = `<text x="95%" y="5%" text-anchor="end" style="font-size: 60px; fill: lightgrey; opacity: 0.5;">code2diagram</text>`;
    }

    // Create the complete SVG with or without the watermark
    const completeSVG = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">${watermark}${diagramSVG}</svg>`;

    localStorage.setItem('MATH_EQ_SVG', completeSVG);

    // Encode the SVG string to Base64
    const base64EncodedSVG = btoa(unescape(encodeURIComponent(completeSVG))); // encode to Base64
    localStorage.setItem('MATH_EQ_SVG_BASE64', base64EncodedSVG);
};

  const clearDiagram = () => {
    if (diagramRef.current) {
      diagramRef.current.innerHTML = '';
    }
  };

  const plotDiagram = () => {
    try {
      const parsedData = YAML.parse(mathEqEditorValue);
      const { xAxis, yAxis, grid, data } = parsedData;

      const plotOptions = {
        target: '#plot',
        width: 650,
        height: 650,
        xAxis,
        yAxis,
        grid,
        data,
      };
      functionPlot(plotOptions);
      setLoading(false);
      downloadChartSVG();
    } catch (error) {
      setLoading(false);
      console.error('Error generating mathEQ diagram:', error);
      setOpenSnackbar(true);
      setSnackbarMessage('Compilation failed. Invalid input format');
      setSnackbarSeverity('error');
    }
  };

  useEffect(() => {
    if (initial) {
      if (mathEqEditorValue.trim() === '') {
        clearDiagram();
        setLoading(false);
        setInitial(false);
        return;
      }
      plotDiagram();
      setInitial(false);
      setLoading(false);
    }
  }, [initial, mathEqEditorValue]);

  useEffect(() => {
    if (!initial) {
      if (mathEqEditorValue.trim() === '') {
        clearDiagram();
        setLoading(false);
        return;
      }
      setLoading(true);
      plotDiagram();
    }
  }, [mathEqEditorValue]);

  useEffect(() => {
    if (diagramRef.current) {
      if (mathEqEditorValue.trim() === '') {
        clearDiagram();
      } else {
        plotDiagram();
      }
    }
  }, []);

  return (
    <div className="alignMiddle" style={{ overflow: 'auto' }}>
      {loading && (
        <div className="alignMiddle">
          <CircularProgress />
          Executing...
        </div>
      )}
      <div id="plot" ref={diagramRef} style={{ display: loading ? 'none' : 'block' }}></div>
    </div>
  );
};

export default MathEquation;
