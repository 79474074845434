import { SET_GANTT_EDITOR_VALUE } from '../actions/ganttChartActions'

const localStorageKey = 'gantt_diagram'

const storedGanttEditorValue = localStorage.getItem(localStorageKey)
const defaultGanttditorValue =
  storedGanttEditorValue ||
  '[ \n{ \n  "start": "jan 5 2024", \n "end": "jun 5 2024", \n  "name": "Project", \n "id": "ProjectSample", \n "progress": 67, \n  "type": "project", \n  "hideChildren": false\n }, \n{ \n "start": "jan 5 2024", \n "end": "mar 13 2024", \n "name": "Idea 1", \n "id": "Task 0", \n "progress": 45, \n "type": "task", \n "project": "ProjectSample"\n },\n { \n "start": "feb 14 2024", \n "end": "may 13 2024", \n "name": "Idea 2", \n "id": "Task 1", \n "progress": 45, \n "type": "task", \n "project": "ProjectSample"\n }\n]'
const initialState = {
  ganttEditorValue: storedGanttEditorValue || defaultGanttditorValue,
}

const ganttEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GANTT_EDITOR_VALUE:
      return {
        ...state,
        ganttEditorValue: action.payload,
      }
    default:
      return state
  }
}

export default ganttEditorReducer
