export const COLORS = {
  PRIMARY_DARK: '#183D3D',
  PRIMARY: '',
  PRIMARY_LIGHT: '#5C8374',
  SECONDARY: '#485c58',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  CALL_TO_ACTION: '#D61F2C',
  EDITOR_COLOR: '#212121',
  GENERATE_IMAGE_BG_COLOR: 'grey',
  ON_HOVER: '#5C8374',
  GOLD:'#FFD700'
};
