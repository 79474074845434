import { Box, Button, Modal, Typography } from '@mui/material'
import React, { useState } from 'react'
import { setAvroSchemaFileValue } from 'src/redux/actions/avroSchemaAction'
import { modalStyle } from 'src/utils/history'

import CloudUploadIcon from '@mui/icons-material/CloudUpload'
const AvscModal = ({ dispatch, setShowUploadModal, showUploadModal }) => {
  const [selectedFile, setSelectedFile] = useState(null)

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setSelectedFile(reader.result)
    }
    reader.onerror = (error) => {
      console.error('Error converting file to Base64:', error)
    }
  }

  const handleUpload = async () => {
    dispatch(setAvroSchemaFileValue(selectedFile))
    setShowUploadModal(false)
  }
  return (
    <Modal open={showUploadModal} onClose={() => setShowUploadModal(false)}>
      <Box sx={modalStyle}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Upload AVSC File
        </Typography>
        <hr />
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          className="icon-button"
        >
          Upload file
          <input type="file" accept=".avsc" onChange={handleFileChange} style={{ display: 'none' }} />
        </Button>
        <footer style={{ display: 'flex', gap: '5px', justifyContent: 'flex-end', margin: '15px' }}>
          <Button size="small" className="cancel-button" onClick={() => setShowUploadModal(false)}>
            Cancel
          </Button>
          <Button size="small" className="icon-button" onClick={() => handleUpload()}>
            Upload
          </Button>
        </footer>
      </Box>
    </Modal>
  )
}

export default AvscModal
