import React from 'react'
import { Helmet } from 'react-helmet'

const C2DHelmet = () => {
  return (
    <div>
      <Helmet>
        <title>Code 2 Diagram</title>
        <meta
          name="description"
          content="Effortlessly generate stunning diagrams from simple code instructions. Designed specifically for rapidly prototyping new system architectures without the need for any design tools"
        />

        {/* Open Graph Meta Tags for Facebook 
      <meta property="og:image" content="https://res.cloudinary.com/djvyd8hea/image/upload/v1699354168/code2diagram.png" />
      
      */}
        <meta property="og:title" content="Code 2 Diagram" />
        <meta
          property="og:description"
          content="Transform plain code into stunning diagrams in the blink of an eye! Born for effortlessly prototyping system architectures, no design tools required!"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/djvyd8hea/image/upload/v1700468222/SocialMedia_gmsmki.jpg"
        />

        <meta property="og:url" content="code2diagram.com" />
        <meta property="og:type" content="website" />
        <meta
          property="og:video"
          content="https://res.cloudinary.com/djvyd8hea/video/upload/v1699030187/Start_converting_your_instructions_to_beautiful_diagrams_pvxk1j.mp4"
        />

        {/* Twitter Card Meta Tags 
      <meta name="twitter:image" content="https://res.cloudinary.com/djvyd8hea/image/upload/v1699354168/code2diagram.png" />
      
      */}
        <meta name="twitter:title" content="Code 2 Diagram" />
        <meta
          name="twitter:description"
          content="Transform plain code into stunning diagrams in the blink of an eye! Born for effortlessly prototyping system architectures, no design tools required!"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/djvyd8hea/image/upload/v1700468222/SocialMedia_gmsmki.jpg"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:player:stream"
          content="https://res.cloudinary.com/djvyd8hea/video/upload/v1699030187/Start_converting_your_instructions_to_beautiful_diagrams_pvxk1j.mp4"
        />
      </Helmet>
    </div>
  )
}

export default C2DHelmet
