import axios from 'axios'
import YAML from 'yaml'

axios.defaults.withCredentials = true

export async function generateSwaggerToUML(
  json,
  isDownload,
  setImageSrc,
  setSnackbarMessage,
  setSnackbarSeverity,
  setOpenSnackbar,
  setVisibleLoading, setOpenSnackbarDiagram
) {
  setOpenSnackbarDiagram(true)
  if (!sessionStorage.getItem('ipAddress')) {
    const userAgent = navigator.userAgent
    const response = await fetch('https://api64.ipify.org?format=json')
    const respJson = await response.json()
    sessionStorage.setItem('ipAddress', respJson.ip)
    sessionStorage.setItem('userAgent', userAgent)
  }

  
  let parsedJson;
  try {
    parsedJson = JSON.parse(json);
  } catch (error) {
    // Not a valid JSON, try to parse as YAML
    try {
      parsedJson = YAML.parse(json);
    } catch (error) {
      setSnackbarMessage('Invalid input format');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      setVisibleLoading(false)
      return;
    }
  }

  const jsonString = JSON.stringify(
    parsedJson,
    (key, value) => {
      if (typeof value === 'string') {
        // Escape backslashes and add newline and carriage return characters
        return value.replace(/\\/g, '\\\\').replace(/\n/g, '\\n').replace(/\r/g, '\\r');
      }
      return value;
    },
    2
  );

  const data = {
    json: jsonString,
    ipAddress: sessionStorage.getItem('ipAddress'),
    userAgent: sessionStorage.getItem('userAgent'),
  }

  let downloadQuery= "?isDownload=false"
    if(isDownload){
      downloadQuery= "?isDownload=true"
  }
  axios
    .post(`${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/swagger2uml/convert`+downloadQuery, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((resp) => {
      setOpenSnackbarDiagram(false)
      setVisibleLoading(false)
      if (resp && resp.data.result.length>0 && resp.data.result[0]==="200") {
        setImageSrc(resp.data.result[1])

        localStorage.setItem('LASTGENERATEDIMAGE_SWAGGER', resp.data.result[1])
        localStorage.setItem('EDITORSTATE_SWAGGER', json)
      } else {
        setSnackbarMessage('Internal Server error')
        setSnackbarSeverity('error')
        setOpenSnackbar(true)
      }
    })
    .catch((error) => {
      setVisibleLoading(false)
      setSnackbarMessage('Internal Server error')
      setSnackbarSeverity('error')
      setOpenSnackbar(true)
    })
}