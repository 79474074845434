import { SET_ARCHGO_EDITOR_VALUE } from '../actions/ArchGoEditorActions'

const localStorageKey = 'archGo_diagram'

const storedArchGoEditorValue = localStorage.getItem(localStorageKey)

const defaultArchGoEditorValue =
  storedArchGoEditorValue ||
  'dns := gcp.Network.Dns(diagram.NodeLabel("DNS"))\nlb := gcp.Network.LoadBalancing(diagram.NodeLabel("NLB"))\ncache := gcp.Database.Memorystore(diagram.NodeLabel("Cache"))\ndb := gcp.Database.Sql(diagram.NodeLabel("Database"))\n\n\ndc := diagram.NewGroup("GCP")\ndc.NewGroup("services").\n    Label("Service Layer").\n    Add(\n        gcp.Compute.ComputeEngine(diagram.NodeLabel("Server 1")),\n        gcp.Compute.ComputeEngine(diagram.NodeLabel("Server 2")),\n        gcp.Compute.ComputeEngine(diagram.NodeLabel("Server 3")),\n    ).\n    ConnectAllFrom(lb.ID(), diagram.Forward()).\n    ConnectAllTo(cache.ID(), diagram.Forward())\n\ndc.NewGroup("data").Label("Data Layer").Add(cache, db).Connect(cache, db)\n\nd.Connect(dns, lb, diagram.Forward()).Group(dc)'

const initialState = {
  archGoEditorValue: storedArchGoEditorValue || defaultArchGoEditorValue,
}

const archGoEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ARCHGO_EDITOR_VALUE:
      return {
        ...state,

        archGoEditorValue: action.payload,
      }

    default:
      return state
  }
}

export default archGoEditorReducer
