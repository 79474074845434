import { Snackbar } from "@mui/material"
import React from "react"
import MuiAlert from "@mui/material/Alert"


const Alert = ({openSnackbar, handleSnackbarClose, snackbarMessage, snackbarSeverity}) => {
  return (
    <Snackbar
      open={openSnackbar}
      autoHideDuration={5000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
      >
        {snackbarMessage}
      </MuiAlert>
    </Snackbar>
  )
}

export default Alert
