import { SET_PLAYBOOK_GRAPHER_EDITOR_VALUE } from '../actions/playbookGrapherEditorAction'

const localStorageKey = 'playbookGrapher_diagram'

const storedPlaybookGrapherEditorValue = localStorage.getItem(localStorageKey)

const defaultPlaybookGrapherEditorValue =
  storedPlaybookGrapherEditorValue ||
  '- hosts: all\n  vars:\n    backport: "stretch-backports"\n    packages:\n      - git\n      - tree\n      - curl\n  pre_tasks:\n    - name: Pre task 1\n      debug: msg="Pretask"\n    - name: Pre task 2\n      debug: msg="Pretask"\n\n  tasks:\n    - name: Add the backbport\n      become: yes\n      apt_repository:\n        repo: deb http://ftp.debian.org/debian {{backport}} main\n        filename: "{{backport}}"\n        state: present\n\n    - name: Install packages\n      become: yes\n      apt:\n        name: "{{packages}}"\n        state: present\n        default_release: "{{backport}}"\n\n    - name: Command that should fails\n      command: /bin/false\n      ignore_errors: true\n\n    - name: This task has "double quotes" in the name\n      command: /bin/true\n\n  post_tasks:\n    - name: Post task\n      debug: msg="Post task 1"\n\n    # We use the same name here just to test if we have two distinct nodes in the graph\n    - name: Post task\n      debug: msg="Post task"'

const initialState = {
  playbookGrapherEditorValue: storedPlaybookGrapherEditorValue || defaultPlaybookGrapherEditorValue,
}

const playbookGrapherEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLAYBOOK_GRAPHER_EDITOR_VALUE:
      return {
        ...state,

        playbookGrapherEditorValue: action.payload,
      }

    default:
      return state
  }
}

export default playbookGrapherEditorReducer
