import { SET_DOCKERCOMPOSE_EDITOR_VALUE } from '../actions/dockerComposeEditorAction'

const localStorageKey = 'dockerCompose_diagram'

const storedDockerComposeEditorValue = localStorage.getItem(localStorageKey)

const defaultDockerComposeEditorValue =
  storedDockerComposeEditorValue ||
  'services:\n  web:\n    image: nginx:latest\n    ports:\n      - "80:80"\n  database:\n    image: postgres:latest\n    environment:\n      POSTGRES_DB: mydatabase\n      POSTGRES_USER: myuser\n      POSTGRES_PASSWORD: mypassword'
const initialState = {
  dockerComposeEditorValue: storedDockerComposeEditorValue || defaultDockerComposeEditorValue,
}

const dockerComposeEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DOCKERCOMPOSE_EDITOR_VALUE:
      return {
        ...state,

        dockerComposeEditorValue: action.payload,
      }

    default:
      return state
  }
}

export default dockerComposeEditorReducer

