import { Box, Button, CircularProgress, Modal, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { handleIconNameChange, uploadAssets } from 'src/views/dashboard/DashboardFunctions'
import DeleteIcon from '@mui/icons-material/Delete'
import { modalStyle } from 'src/utils/history'

const UploadModal = ({
  uploadThreshold,
  setSnackbarMessage,
  setSnackbarSeverity,
  setOpenSnackbar,
  getAccessTokenSilently,
  setUploading,
  setShowUpload,
  showUpload,
  uploading,
  handleUpgrade,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [iconNames, setIconNames] = useState([])

  useEffect(() => {
    if (!showUpload) {
      setSelectedFiles([])
      setIconNames([])
    }
  }, [showUpload])

  const handleFileChange = (acceptedFiles) => {
    const pngFiles = acceptedFiles.filter(
      (file) =>
        file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg',
    )
    const imageList = selectedFiles.concat(pngFiles)
    setSelectedFiles(imageList)
  }

  const renderPreview = () => {
    if (!selectedFiles.length) return <p>No files selected.</p>

    return (
      <ul>
        {selectedFiles.map((file, index) => (
          <div key={file.name}>
            <div className="input-see">
              <TextField
                hiddenLabel
                id="filled-hidden-label-small"
                placeholder="icon name *"
                onChange={(e) => handleIconNameChange(index, e.target.value, iconNames, setIconNames)}
                size="small"
                sx={{
                  '& .MuiInputBase-input': {
                    color: 'black',
                  },
                }}
              />
              <img
                className="img"
                src={URL.createObjectURL(file)}
                alt={file.name}
                width="75"
                height="75"
              />
              <Button
                className="icon-button"
                type="button"
                onClick={() => setSelectedFiles(selectedFiles.filter((f) => f !== file))}
              >
                <DeleteIcon fontSize="small" />
              </Button>
            </div>
          </div>
        ))}
      </ul>
    )
  }

  async function upload() {
    if (uploadThreshold === 0) {
      setSnackbarMessage('Please Upgrade for adding more Assets')
      setSnackbarSeverity('warning')
      setOpenSnackbar(true)
      return
    } else if (uploadThreshold <= 3) {
      setSnackbarMessage('Hurry up Upgrade for adding more Assets')
      setSnackbarSeverity('warning')
      setOpenSnackbar(true)
    }
    const token = await getAccessTokenSilently()
    uploadAssets(token, selectedFiles, iconNames, setUploading, function (response) {
      const selectevent = new CustomEvent('userAssetsUploaded', { detail: 'OK' })
      document.dispatchEvent(selectevent)

      setIconNames([])
      setSelectedFiles([])
      setUploading(false)
      setShowUpload(false)
      setSelectedFiles([])
      const error = response.data.error
      if (!error) {
        setSnackbarMessage('Icon(s) Uploaded Successfully')
        setSnackbarSeverity('success')
        setOpenSnackbar(true)
      } else {
        setSnackbarMessage('Upload Failed')
        setSnackbarSeverity('error')
        setOpenSnackbar(true)
      }
    })
  }
  return (
    <Modal open={showUpload} onClose={() => setShowUpload(false)}>
      <Box sx={modalStyle}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Upload Your Asset
        </Typography>
        <hr />
        {uploadThreshold !== 0 ? (
          <div>
            <Dropzone onDrop={handleFileChange}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="dropzone">
                  <input {...getInputProps()} />
                  <Button className="icon-button">Click to Select</Button>
                  <p>(Only png, jpg or jpeg supported)</p>
                </div>
              )}
            </Dropzone>
            {renderPreview()}
          </div>
        ) : (
          <p>
            Oops! You&apos;ve reached the 10-icon upload limit, Upgrade to Premium for unlimited
            icon uploads and exclusive features!
          </p>
        )}
        <hr />
        <footer style={{ display: 'flex', gap: '5px', justifyContent: 'flex-end', margin: '15px' }}>
          <p>Remaining Upload: {uploadThreshold}</p>
          {sessionStorage.getItem('isSubscribed') !== 'true' && (
            // Upgrade to upload upto 200 assets
            <p></p>
          )}
          {!uploading && uploadThreshold > 0 && (
            <Button
              size="small"
              className="icon-button"
              onClick={() => {
                upload()
              }}
            >
              Upload
            </Button>
          )}
          {uploading && (
            <Button color="error">
              <CircularProgress size="sm" />
              Uploading...
            </Button>
          )}
          {uploadThreshold <= 0 && (
            <Button color="warning" size="small" className="icon-button" onClick={handleUpgrade}>
              Upgrade
            </Button>
          )}
        </footer>
      </Box>
    </Modal>
  )
}

export default UploadModal
