import { CSidebar, CSidebarNav } from '@coreui/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AppSidebarNav } from './AppSidebarNav'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedMode } from 'src/redux/actions/StateAction'
import Logo from 'src/assets/c2d_original_v2_with_text.png'
import { CONSTANTS } from './Constants'

const AppSidebar = () => {
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const [ShowSearchBar, setShowSearchBar] = useState(false)
  const [allIcons, setAllIcons] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const dispatch = useDispatch()

  axios.defaults.withCredentials = true

  useEffect(() => {
    if (!localStorage.getItem('selectedMode') || localStorage.getItem('selectedMode') === CONSTANTS.ARCHITECTURE_DIAGRAM){
      setShowSearchBar(true)
    }
    if (localStorage.getItem('result')) {
      const parsedResult = JSON.parse(localStorage.getItem('result'))
      setAllIcons(parsedResult)
    } else {
      axios
        .get(`${process.env.REACT_APP_SIDEBAR_API_URL}/IconResource.json`)
        .then((response) => {
          const result = response.data.platformList
          localStorage.setItem('result', JSON.stringify(result))
          const parsedResult = JSON.parse(localStorage.getItem('result'))
          setAllIcons(parsedResult)
        })
        .catch((err) => {
          console.error(err.Message)
        })
    }
  }, [])
  useEffect(() => {
    function recursiveSearch(str) {
      var res = []
      function searchAndPush(obj, str) {
        var foundObj = {}

        for (var key1 in obj) {
          if (key1 === 'Getting Started' || key1 === 'Examples') continue
          if (obj.hasOwnProperty(key1) && Array.isArray(obj[key1])) {
            foundObj[key1] = []
            for (var j = 0; j < obj[key1].length; j++) {
              var subObj = obj[key1][j]
              for (var key2 in subObj) {
                if (subObj.hasOwnProperty(key2) && Array.isArray(subObj[key2])) {
                  var subArray = subObj[key2]
                  var filteredSubArray = subArray.filter((item) =>
                    item.code.toLocaleLowerCase().includes(str),
                  )
                  if (key1.toLocaleLowerCase() === str || key2.toLocaleLowerCase() === str) {
                    filteredSubArray = subArray
                  }
                  if (filteredSubArray.length > 0) {
                    foundObj[key1].push({ [key2]: filteredSubArray })
                  }
                }
              }
            }
          }
        }
        if (Object.keys(foundObj).length > 0) {
          res.push(foundObj)
        }
      }
      for (var i = 0; i < allIcons.length; i++) {
        searchAndPush(allIcons[i], str)
      }
      res = res.filter(
        (obj) =>
          Object.keys(obj).length > 0 && Object.values(obj).every((value) => value.length > 0),
      )
      if (res.length > 0) setSearchResults(res)
    }
    recursiveSearch(searchValue)
  }, [searchValue])
  const searchHandle = (e) => {
    var inputSearch = e.target.value
    inputSearch = inputSearch.toLocaleLowerCase()
    setSearchValue(inputSearch)
  }

  document.addEventListener('selectedMode', function (event) {
    setShowSearchBar(false) 
    event.stopPropagation()
    event.preventDefault()
    if (event.detail) {
      if (event.detail === CONSTANTS.ARCHITECTURE_DIAGRAM) setShowSearchBar(true) 
      dispatch(setSelectedMode(event.detail))
    }
  })

  return (
    <CSidebar position="fixed" visible={sidebarShow}>
      <Link to="/">
        <img src={Logo} className="logo" alt="logo" height={40} />
      </Link>
      <CSidebarNav>
        {ShowSearchBar && (
          <div className="input-group mb-3">
          <input
            style={{
              backgroundColor: 'white',
              color: 'black',
              margin: '0px 5px',
            }}
            type="text"
            className="form-control shadow-none"
            placeholder="🔍 Search "
            aria-label="Search"
            value={searchValue}
            onChange={searchHandle}
          />
        </div>
        )}
        <AppSidebarNav items={searchValue ? searchResults : allIcons} />
      </CSidebarNav>
    </CSidebar>
  )
}
export default React.memo(AppSidebar)
