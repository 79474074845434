import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PanZoom } from 'react-easy-panzoom'
import axios from 'axios'
import { generateAvroSchemaDiagram } from 'src/views/dashboard/DashboardFunctions'
import { CONSTANTS } from '../Constants'
import { MapContainer } from 'react-leaflet'
import { CircularProgress } from '@mui/material'

const AvroSchema = ({
  panZoomRef,
  setOpenSnackbar,
  setOpenSnackbarDiagram,
  setSnackbarMessage,
  setSnackbarSeverity,
}) => {
  let editorValue = useSelector((state) => state.avroSchemaEditor.avroSchemaEditorValue)
  let fileValue = useSelector((state) => state.avroSchemaEditor.avroSchemaFileValue)
  const [avroSchemaImage, setAvroSchemaImage] = useState('')
  const [loading, setLoading] = useState(true)
  const [previousCode, setPreviousCode] = useState('')
  const [initial, setInitial] = useState(true)

  axios.defaults.withCredentials = true

  const generateAvroSchemaImage = async (fileValue) => {
    try {
      if(!editorValue.trim()){
        setAvroSchemaImage('');
        setPreviousCode('')
        return
      }
      if (!fileValue && previousCode.trim() === editorValue.trim()) return
      setPreviousCode(editorValue)
      setOpenSnackbarDiagram(true)
      const outputImage = await generateAvroSchemaDiagram(editorValue, false, fileValue)
      if (outputImage != null && !outputImage.includes('failed to compile')) {
        setAvroSchemaImage(outputImage)
        localStorage.setItem('AVROSCHEMA_IMG', outputImage)
        setLoading(false)
        setOpenSnackbarDiagram(false)
      } else {
        setLoading(false)
        setOpenSnackbar(true)
        setSnackbarMessage('Compilation failed. Invalid input format')
        setSnackbarSeverity('error')
        setOpenSnackbarDiagram(false)
      }
    } catch (error) {
      console.error('Error generating avroSchema diagram:', error)
      setLoading(false)
      setOpenSnackbar(true)
      setSnackbarMessage('Compilation failed. Invalid input format')
      setSnackbarSeverity('error')
    }
  }

  useEffect(() => {
    if (panZoomRef.current) {
      panZoomRef.current.setOptions({ preventScroll: true });
    }
  }, [panZoomRef]);

  useEffect(() => {
    if (initial) {
      generateAvroSchemaImage(null)
      setInitial(false)
    }

    if (!initial) {
      const intervalId = setInterval(generateAvroSchemaImage, 4000)
      return () => clearInterval(intervalId)
    }
    localStorage.setItem('selectedMode', CONSTANTS.AVROSCHEMA)
  }, [editorValue, previousCode, initial, fileValue])

  useEffect(() => {
    generateAvroSchemaImage(fileValue)
  }, [fileValue])

  return (
    <div className="alignMiddle" style={{ overflow: 'auto' }}>
      {loading && (
        <div className="alignMiddle">
          <CircularProgress />
          Executing...
        </div>
      )}
      <MapContainer
        center={[51.505, -0.09]}
        zoom={13}
        attributionControl={false}
        zoomControl={false}
      >
        <PanZoom ref={panZoomRef}>
          <div className="alignMiddle">
            {!loading && (avroSchemaImage ? (
              <img
                src={`data:image/png;base64,${avroSchemaImage}`}
                alt="AvroSchema Diagram"
                className="image-style"
              />
            ) : (
              <div style={{ color: 'gray', fontSize: '24px' }}></div>
            ))}
            
          </div>
        </PanZoom>
      </MapContainer>
    </div>
  )
}
export default AvroSchema
