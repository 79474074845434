import { SET_MATHEQ_EDITOR_VALUE } from '../actions/mathEqAction'

const localStorageKey = 'mathEq_diagram'

const storedMathEqEditorValue = localStorage.getItem(localStorageKey)

const defaultMathEqEditorValue =
  storedMathEqEditorValue ||
  `xAxis:\n  label: 'time'\n  domain: [-6, 6]\nyAxis:\n  label: 'growth'\ndata:\n - fn: '3 + sin(x)'`
const initialState = {
  mathEqEditorValue: storedMathEqEditorValue || defaultMathEqEditorValue,
}

const mathEqEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MATHEQ_EDITOR_VALUE:
      return {
        ...state,
        mathEqEditorValue: action.payload,
      }

    default:
      return state
  }
}

export default mathEqEditorReducer
