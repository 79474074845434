import { SET_TERRAFORM_EDITOR_VALUE } from '../actions/terraformActions'

const localStorageKey = 'terraform_diagram'

const storedTerraformEditorValue = localStorage.getItem(localStorageKey)

const defaultTerraformEditorValue =
  storedTerraformEditorValue ||
'provider "aws" {\n  region = "us-west-2"\n}\n\nresource "aws_instance" "example" {\n  ami           = "ami-0c94855ba95c574c8"\n  instance_type = "t2.micro"\n\n  tags = {\n    Name = "example-instance"\n  }\n}'
const initialState = {
  terraformEditorValue: storedTerraformEditorValue || defaultTerraformEditorValue,
}

const terraformEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TERRAFORM_EDITOR_VALUE:
      return {
        ...state,

        terraformEditorValue: action.payload,
      }

    default:
      return state
  }
}

export default terraformEditorReducer
