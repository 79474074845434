import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { PanZoom } from 'react-easy-panzoom'
import axios from 'axios'
import { generateBrainstormDiagram } from 'src/views/dashboard/DashboardFunctions'
import { CONSTANTS } from '../Constants'
import { MapContainer } from 'react-leaflet'
import { CircularProgress } from '@mui/material'
import _ from 'lodash'

const Brainstorm = ({
  panZoomRef,
  setOpenSnackbar,
  setOpenSnackbarDiagram,
  setSnackbarMessage,
  setSnackbarSeverity,
}) => {
  let editorValue = useSelector((state) => state.brainstormEditor.brainstormEditorValue)
  const [initial, setInitial] = useState(true)
  const [brainstormImage, setBrainstormImage] = useState('')
  const [loading, setLoading] = useState(true)
  const [previousCode, setPreviousCode] = useState('')

  axios.defaults.withCredentials = true

  const convertIndentedToBracket = (input) => {
    const lines = input.split('\n').filter(line => line.trim() !== '');
    let output = '';
    let currentIndent = 0;
    let closingCounter = 0;
    const labelCount = {};
    const labelMap = {};

    const getUniqueLabel = (label) => {
        if (labelCount[label] === undefined) {
            labelCount[label] = 0;
        } else {
            labelCount[label] += 1;
        }
        const uniqueLabel = labelCount[label] > 0 ? `${label}${labelCount[label] + 1}` : label;
        return uniqueLabel;
    };

    lines.forEach((line, index) => {
        const indent = line.search(/\S/);
        line = line.trim();
        if (line.includes('->')) {
            return;
        }
        const uniqueLabel = getUniqueLabel(line);
        labelMap[line] = uniqueLabel;
        const displayLabel = uniqueLabel !== line ? `${uniqueLabel}: "${line}"` : uniqueLabel;

        if (indent > currentIndent) {
            output += ` { ${displayLabel}`;
            currentIndent = indent;
            closingCounter += 1;
        } else if (indent < currentIndent) {
            const diff = (currentIndent - indent) / 4;  // assuming indentations are in multiples of 4 spaces
            output += ' }'.repeat(diff) + `\n${displayLabel}`;
            currentIndent = indent;
            closingCounter -= diff;
        } else {
            if (index > 0) {
                output += `\n${displayLabel}`;
            } else {
                output += `${displayLabel}`;
            }
        }
    });

    while (closingCounter > 0) {
        output += ' }';
        closingCounter--;
    }

    return output.trim();
};

const createMapping = (input) => {
    let valueMap = parseYaml(input);
    const lines = input.split('\n');
    const mappings = lines.filter(line => line.includes('->'));
    const result = mappings.map(line => transformString(line, valueMap)).join('\n');

    return result;
};

const transformString = (input, valueMap) => {
    const [left, right] = input.split('->').map(part => part.trim());
    const leftPart = valueMap[left];
    const rightPart = valueMap[right];
    return `${leftPart} -> ${rightPart}`;
};

const parseYaml = (yaml) => {
    const lines = yaml.trim().split('\n');
    const map = {};
    const labelCount = {};

    const getUniqueLabel = (label, parentKey) => {
        const key = parentKey ? `${parentKey}.${label}` : label;
        if (labelCount[key] === undefined) {
            labelCount[key] = 0;
        } else {
            labelCount[key] += 1;
        }
        const uniqueLabel = labelCount[key] > 0 ? `${label}${labelCount[key] + 1}` : label;
        return uniqueLabel;
    };

    const parseLine = (line, parentKey) => {
        let label = line;
        let originalLabel = line;
        if (line.includes(':')) {
            [label, originalLabel] = line.split(':').map(part => part.trim().replace(/"/g, ''));
        }
        const fullKey = parentKey ? `${parentKey}.${label}` : label;
        map[label] = fullKey;
        if (originalLabel !== label) {
            map[originalLabel] = fullKey;
        }
    };

    const traverse = (lines, parentKey = '') => {
        let currentIndent = null;
        let currentKey = null;

        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            if (line.includes('->')) {
                continue;
            }
            if (line.trim() === '') continue;

            const indent = line.search(/\S/);
            const trimmedLine = line.trim();

            if (currentIndent === null) {
                currentIndent = indent;
                currentKey = trimmedLine;
                parseLine(trimmedLine, parentKey);
            } else if (indent > currentIndent) {
                const childLines = [];
                for (let j = i; j < lines.length; j++) {
                    if (lines[j].search(/\S/) > currentIndent) {
                        childLines.push(lines[j]);
                    } else {
                        break;
                    }
                }
                traverse(childLines, map[currentKey]);
                i += childLines.length - 1;
            } else {
                currentIndent = indent;
                currentKey = trimmedLine;
                parseLine(trimmedLine, parentKey);
            }
        }
    };

    traverse(lines);
    return map;
};


  useEffect(() => {
    if (panZoomRef.current) {
      panZoomRef.current.setOptions({ preventScroll: true })
    }
  }, [panZoomRef])

  const generateBrainstormImage = useCallback(
    _.debounce(async () => {
      try {
        if (!editorValue.trim()){
          setBrainstormImage('')
          setPreviousCode('')
          return
        }

        if (previousCode.trim() === editorValue.trim()) return
        setPreviousCode(editorValue)
        setOpenSnackbarDiagram(true)
        let output = convertIndentedToBracket(editorValue)
        output += '\n'
        output += createMapping(editorValue)
        console.log(output)
        sessionStorage.setItem('BRAINSTORM_COMPILED_CODE', output);
        const outputImage = await generateBrainstormDiagram(output)
        if (outputImage != null && !outputImage.includes('failed')) {
          setBrainstormImage(outputImage)
          localStorage.setItem('BRAINSTORM_IMG', outputImage)
          setLoading(false)
          setOpenSnackbarDiagram(false)
        } else {
          setLoading(false)
          setOpenSnackbar(true)
          setSnackbarMessage('Compilation failed. Invalid input format')
          setSnackbarSeverity('error')
          setOpenSnackbarDiagram(false)
        }
      } catch (error) {
        console.error('Error generating brainstorm diagram:', error)
        setLoading(false)
        setOpenSnackbar(true)
        setSnackbarMessage('Compilation failed. Invalid input format')
        setSnackbarSeverity('error')
      }
    }, 500),
    [
      editorValue,
      previousCode,
      setOpenSnackbar,
      setOpenSnackbarDiagram,
      setSnackbarMessage,
      setSnackbarSeverity,
    ],
  )

  useEffect(() => {
    if (initial) {
      generateBrainstormImage()
      setInitial(false)
    } else {
      if (!initial) {
        const intervalId = setInterval(generateBrainstormImage, 3000)
        return () => clearInterval(intervalId)
      }
      localStorage.setItem('selectedMode', CONSTANTS.BRAINSTORM)
    }
  }, [editorValue, previousCode, initial])

  return (
    <div className="alignMiddle" style={{ overflow: 'auto' }}>
      <MapContainer
        center={[51.505, -0.09]}
        zoom={13}
        attributionControl={false}
        zoomControl={false}
      >
        <PanZoom ref={panZoomRef}>
          {loading && (
            <div className="alignMiddle">
              <CircularProgress />
              Executing...
            </div>
          )}
          {!loading && (brainstormImage ? (
            <img
              src={`data:image/svg+xml;base64,${brainstormImage}`}
              alt="Brainstorm Diagram"
              className="image-style"
            />
          ) : (
            <div style={{ color: 'gray', fontSize: '24px' }}></div>
          ))}           

        </PanZoom>
      </MapContainer>
    </div>
  )
}

export default Brainstorm
