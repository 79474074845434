import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PanZoom } from 'react-easy-panzoom'
import axios from 'axios'
import { generateArchGoDiagram } from 'src/views/dashboard/DashboardFunctions'
import { CONSTANTS } from '../Constants'
import { MapContainer } from 'react-leaflet'
import { CircularProgress } from '@mui/material'

const ArchGo = ({
  diagramName,
  direction,
  setOpenSnackbar,
  setOpenSnackbarDiagram,
  setSnackbarMessage,
  setSnackbarSeverity,
  panZoomRef,
}) => {
  let editorValue = useSelector((state) => state.archGoEditor.archGoEditorValue)
  const [initial, setInitial] = useState(true)
  const [archGoImage, setArchGoImage] = useState('')
  const [loading, setLoading] = useState(true)
  const [previousCode, setPreviousCode] = useState('')

  useEffect(() => {
    if (panZoomRef.current) {
      panZoomRef.current.setOptions({ preventScroll: true });
    }
  }, [panZoomRef]);

  axios.defaults.withCredentials = true

  useEffect(() => {
    const generateArchGoImage = async (jsonData) => {
      try {
        if(!editorValue.trim()){
          setArchGoImage('')
          setPreviousCode('')
          return
        }
        if (previousCode.trim() !== editorValue.trim()) {
          setOpenSnackbarDiagram(true)
          setPreviousCode(editorValue)
          let outputImage = await generateArchGoDiagram(jsonData)
          if (
            outputImage != null &&
            !outputImage.includes('failed') &&
            !outputImage.includes('error')
          ) {
            setArchGoImage(outputImage)
            localStorage.setItem('ARCHGO_IMG', outputImage)
            setLoading(false)
            setOpenSnackbarDiagram(false)
          } else {
            setOpenSnackbar(true)
            setSnackbarMessage('Compilation failed. Invalid input format')
            setSnackbarSeverity('error')
            setOpenSnackbarDiagram(false)
            setLoading(false)
          }
        } else if (direction !== localStorage.getItem('ORIENTATIONS')) {
          setOpenSnackbarDiagram(true)
          setPreviousCode(editorValue)
          let outputImage = await generateArchGoDiagram(jsonData)
          if (
            outputImage != null &&
            !outputImage.includes('failed') &&
            !outputImage.includes('error')
          ) {
            setArchGoImage(outputImage)
            localStorage.setItem('ARCHGO_IMG', outputImage)
            setLoading(false)
            setOpenSnackbarDiagram(false)
          } else {
            setOpenSnackbar(true)
            setSnackbarMessage('Compilation failed. Invalid input format')
            setSnackbarSeverity('error')
            setOpenSnackbarDiagram(false)
            setLoading(false)
          }
        }
        localStorage.setItem('ORIENTATIONS', direction)
      } catch (error) {
        console.error('Error generating archGo diagram:', error)
        setOpenSnackbar(true)
        setSnackbarMessage('Compilation failed. Invalid input format')
        setSnackbarSeverity('error')
        setLoading(false)
      }
    }
    let jsonData = {
      code: editorValue,
      orientation: direction,
      diagramName: diagramName,
    }
    if (initial) {
      generateArchGoImage(jsonData)
      setInitial(false)
    }
    if (!initial) {
      const intervalId = setInterval(() => generateArchGoImage(jsonData), 3000)
      return () => clearInterval(intervalId)
    }

    localStorage.setItem('selectedMode', CONSTANTS.ARCHGO)
  }, [editorValue, previousCode, initial, diagramName, direction])

  return (
    <div className="alignMiddle" style={{ overflow: 'auto' }}>
      {loading && (
        <div className="alignMiddle">
          <CircularProgress />
          Executing...
        </div>
      )}
      <MapContainer
        center={[51.505, -0.09]}
        zoom={13}
        attributionControl={false}
        zoomControl={false}
      >
        <PanZoom ref={panZoomRef}>
        <div className="alignMiddle">
          {!loading && (archGoImage?(
          
              <img
                src={`data:image/jpeg+xml;base64,${archGoImage}`}
                alt="ArchGo Diagram"
                className="image-style"
              />
          
            ) : (
              <div style={{ color: 'gray', fontSize: '24px' }}></div>
            ))}
          </div>
        </PanZoom>
      </MapContainer>
    </div>
  )
}
export default ArchGo
