import { Auth0Provider } from "@auth0/auth0-react";
import "core-js";
import React from "react";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import configureStore from "./redux/store/configureStore";
import reportWebVitals from "./reportWebVitals";
import history from "./utils/history";

const store = configureStore();

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const providerConfig = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_CLIENT_ID,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin+"/console",
    ...("https://code2diagram.com" ? { audience: "https://code2diagram.com" } : null),
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider {...providerConfig}>
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>,
);

reportWebVitals();
