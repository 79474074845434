import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PanZoom } from 'react-easy-panzoom'
import axios from 'axios'
import { generateInventoryGrapherDiagram } from 'src/views/dashboard/DashboardFunctions'
import { CONSTANTS } from '../Constants'
import { MapContainer } from 'react-leaflet'
import { CircularProgress } from '@mui/material'

const InventoryGrapher = ({
  panZoomRef,
  setOpenSnackbar,
  setOpenSnackbarDiagram,
  setSnackbarMessage,
  setSnackbarSeverity,
}) => {
  let editorValue = useSelector((state) => state.inventoryGrapher.inventoryGrapherEditorValue)
  const [initial, setInitial] = useState(true)
  const [inventoryGrapherImage, setInventoryGrapherImage] = useState('')
  const [loading, setLoading] = useState(true)
  const [previousCode, setPreviousCode] = useState('')

  axios.defaults.withCredentials = true

  useEffect(() => {
    if (panZoomRef.current) {
      panZoomRef.current.setOptions({ preventScroll: true });
    }
  }, [panZoomRef]);

  useEffect(() => {
    const generateInventoryGrapherImage = async () => {
      try {
        if(!editorValue.trim()){
          setInventoryGrapherImage('')
          setPreviousCode('')
          return
        }
        if (previousCode.trim() === editorValue.trim()) return
        setPreviousCode(editorValue)
        setOpenSnackbarDiagram(true)
        const outputImage = await generateInventoryGrapherDiagram(editorValue)
        if (outputImage != null && !outputImage.includes('failed to compile')) {
          setInventoryGrapherImage(outputImage)
          localStorage.setItem('InventoryGrapher_IMG', outputImage)
          setLoading(false)
          setOpenSnackbarDiagram(false)
        } else {
          setLoading(false)
          setOpenSnackbar(true)
          setSnackbarMessage('Compilation failed. Invalid input format')
          setSnackbarSeverity('error')
          setOpenSnackbarDiagram(false)
        }
      } catch (error) {
        console.error('Error generating inventoryGrapher diagram:', error)
        setLoading(false)
        setOpenSnackbar(true)
        setSnackbarMessage('Compilation failed. Invalid input format')
        setSnackbarSeverity('error')
      }
    }
    if (initial) {
      generateInventoryGrapherImage()
      setInitial(false)
    }

    if (!initial) {
      const intervalId = setInterval(generateInventoryGrapherImage, 4000)
      return () => clearInterval(intervalId)
    }
    localStorage.setItem('selectedMode', CONSTANTS.INVENTORY_GRAPHER)
  }, [editorValue, previousCode, initial])

  return (
    <div className="alignMiddle" style={{ overflow: 'auto' }}>
      <MapContainer
        center={[51.505, -0.09]}
        zoom={13}
        attributionControl={false}
        zoomControl={false}
      >
        <PanZoom ref={panZoomRef}>
        <div className="alignMiddle">
  {loading ? (
    <div className="alignMiddle">
      <CircularProgress />
      Executing...
    </div>
  ) : (
    inventoryGrapherImage ? (
      <img
        src={`data:image/png+xml;base64,${inventoryGrapherImage}`}
        alt="InventoryGrapher Diagram"
        className="image-style"
      />
    ) : (
      <div style={{ color: 'gray', fontSize: '24px' }}></div>
    )
  )}
</div>
        </PanZoom>
      </MapContainer>
    </div>
  )
}
export default InventoryGrapher
