import { SET_INVENTORY_GRAPHER_EDITOR_VALUE } from '../actions/inventoryGrapherEditorAction'

const localStorageKey = 'inventoryGrapher_diagram'

const storedInventoryGrapherEditorValue = localStorage.getItem(localStorageKey)

const defaultInventoryGrapherEditorValue =
  storedInventoryGrapherEditorValue ||
  '[web]\nweb1.example.com ansible_host=192.168.1.101 ansible_user=admin\nweb2.example.com ansible_host=192.168.1.102 ansible_user=admin\n\n[database]\ndb1.example.com ansible_host=192.168.1.103 ansible_user=admin\n\n[app:children]\nweb\ndatabase'
const initialState = {
  inventoryGrapherEditorValue:
    storedInventoryGrapherEditorValue || defaultInventoryGrapherEditorValue,
}

const inventoryGrapherEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVENTORY_GRAPHER_EDITOR_VALUE:
      return {
        ...state,

        inventoryGrapherEditorValue: action.payload,
      }

    default:
      return state
  }
}

export default inventoryGrapherEditorReducer
