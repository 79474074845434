export const SET_BRAINSTORM_EDITOR_VALUE = "SET_BRAINSTORM_EDITOR_VALUE";

export const setBrainstormEditorValue = (brainstormEditorValue) => ({
  type: SET_BRAINSTORM_EDITOR_VALUE,
  payload: brainstormEditorValue,
});

export const SET_D2_EDITOR_VALUE = "SET_D2_EDITOR_VALUE";

export const setD2EditorValue = (d2EditorValue) => ({
  type: SET_D2_EDITOR_VALUE,
  payload: d2EditorValue,
});

export const SET_BRAINSTORM_IMAGE_VALUE = 'SET_BRAINSTORM_IMAGE_VALUE';

export const setBrainstormImageValue = (brainstormImageValue) => ({
  type: SET_BRAINSTORM_IMAGE_VALUE,
  payload: brainstormImageValue,
});