import React, { useState, useEffect } from "react"
import { ViewMode, Gantt } from "gantt-task-react"
import { ViewSwitcher } from "../ViewSwitcher"
import { useSelector } from "react-redux"
import { CONSTANTS } from "../Constants"

const GanttChart = ({}) => {
  let editorValue = useSelector((state) => state.ganttEditor.ganttEditorValue)

  const [view, setView] = useState(ViewMode.Month)
  const [tasks, setTasks] = useState(null)
  const [isChecked, setIsChecked] = useState(false)
  let columnWidth = 60
  if (view === ViewMode.Month) {
    columnWidth = 60
  } else if (view === ViewMode.Week) {
    columnWidth = 30
  }
  else if (view === ViewMode.Day){
    columnWidth = 45
  }

  function parseDateString(dateString) {
    const parts = dateString.split(" ")
    const month = parts[0]
    const day = parseInt(parts[1])
    const year = parseInt(parts[2])

    const monthMap = {
      jan: 0,
      feb: 1,
      mar: 2,
      apr: 3,
      may: 4,
      jun: 5,
      jul: 6,
      aug: 7,
      sep: 8,
      oct: 9,
      nov: 10,
      dec: 11,
    }

    const monthIndex = monthMap[month.toLowerCase()]

    if (isNaN(day) || isNaN(year) || monthIndex === undefined) {
      return null // Invalid date string
    }

    return new Date(year, monthIndex, day)
  }

  function parseJSONDates(jsonDataString) {
    let jsonData = JSON.parse(jsonDataString)
    return jsonData.map((item) => {
      if (item.start) {
        item.start = parseDateString(item.start)
      }
      if (item.end) {
        item.end = parseDateString(item.end)
      }
      return item
    })
  }

  const handleTaskDelete = (task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?")
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id))
    }
    return conf
  }
  const handleProgressChange = async (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)))
  }

  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)))
  }

  function getStartEndDateForProject(tasks, projectId) {
    const projectTasks = tasks.filter((t) => t.project === projectId)
    let start = projectTasks[0].start
    let end = projectTasks[0].end
    for (let i = 0; i < projectTasks.length; i++) {
      const task = projectTasks[i]
      if (start.getTime() > task.start.getTime()) {
        start = task.start
      }
      if (end.getTime() < task.end.getTime()) {
        end = task.end
      }
    }
    return [start, end]
  }

  const handleTaskChange = (task) => {
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t))
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project)
      const project = newTasks[newTasks.findIndex((t) => t.id === task.project)]
      if (project.start.getTime() !== start.getTime() || project.end.getTime() !== end.getTime()) {
        const changedProject = { ...project, start, end }
        newTasks = newTasks.map((t) => (t.id === task.project ? changedProject : t))
      }
    }
    setTasks(newTasks)
  }

  useEffect(() => {
    let change = true
    try {
      editorValue = parseJSONDates(editorValue)
      if (change && JSON.stringify(editorValue) !== JSON.stringify(tasks)) {
        setTasks(editorValue)
      }
    } catch (error) {}
    localStorage.setItem("selectedMode", CONSTANTS.GANTT_CHART);
  }, [editorValue, tasks])

  return (
    <div className="alignMiddle" style={{ overflow: 'auto' }}>
      {tasks && (
        <div className="content">
          <ViewSwitcher
            onViewModeChange={(viewMode) => setView(viewMode)}
            onViewListChange={setIsChecked}
            isChecked={isChecked}
          />
          <Gantt
            tasks={tasks.map((task, index) => ({ ...task, key: `Task ${index}` }))}
            viewMode={view}
            onDateChange={handleTaskChange}
            onDelete={handleTaskDelete}
            onProgressChange={handleProgressChange}
            onExpanderClick={handleExpanderClick}
            listCellWidth={isChecked ? "155px" : ""}
            columnWidth={columnWidth}
            barBackgroundColor="#ff7f0e"
            rowHeight={60}
            fontSize="12"
            barCornerRadius={5}
            barProgressColor="#2ca02c"
          />
        </div>
      )}
    </div>
  )
}
export default GanttChart
