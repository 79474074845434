import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PanZoom } from 'react-easy-panzoom'
import axios from 'axios'
import { CONSTANTS } from '../Constants'
import { MapContainer } from 'react-leaflet'
import { CircularProgress } from '@mui/material'
import { generateArchitectureDiagramFn } from 'src/views/dashboard/DashboardFunctions'

const ArchitectureDiagram = ({
  diagramName,
  setVisibleLoading,
  setImageWidth,
  setImageHeight,
  setPythonError,
  setSnackbarMessage,
  setSnackbarSeverity,
  setOpenSnackbar,
  direction,
  panZoomRef,
  setOpenSnackbarDiagram,
}) => {
  let editorValue = useSelector((state) => state.architectureEditor.architectureEditorValue)
  const [loading, setLoading] = useState(true)
  const [initial, setInitial] = useState(true)
  const [previousCode, setPreviousCode] = useState('')
  const [imageSrc, setImageSrc] = useState('')
  const [previousImageSrc, setPreviousImageSrc] = useState('')

  useEffect(() => {
    if (panZoomRef.current) {
      panZoomRef.current.setOptions({ preventScroll: true });
    }
  }, [panZoomRef]);

  axios.defaults.withCredentials = true

  function generateArchitectureDiagram(code, isDownload, callback) {
    setOpenSnackbarDiagram(true)

    if (!sessionStorage.getItem('ipAddress')) {
      const userAgent = navigator.userAgent
      fetch('https://api64.ipify.org?format=json')
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
        .then((respJson) => {
          const userAgent = navigator.userAgent
          sessionStorage.setItem('ipAddress', respJson.ip)
          sessionStorage.setItem('userAgent', userAgent)
        })
        .catch((error) => {
          console.error('Error fetching IP address:', error)
        })
    }

    generateArchitectureDiagramFn(editorValue, isDownload, setImageHeight, setImageWidth, setImageSrc, 
      setPythonError, setSnackbarMessage, setSnackbarSeverity, setOpenSnackbar, setOpenSnackbarDiagram,
      diagramName, direction, setVisibleLoading, function(resp){
        setLoading(false)
        callback(resp)
    })
  }

  useEffect(() => {
    function generate(isDownload) {
      if (!editorValue || editorValue.trim() === ''){
        setImageSrc('')
        setPreviousCode('')
        return

      }
      
      if (previousCode.trim() !== editorValue.trim()) {
        let orientation = 'TB'
        if (localStorage.getItem('ORIENTATIONS')) {
          orientation = localStorage.getItem('ORIENTATIONS')
        }
        let tempData = editorValue
        tempData = "DIAGRAM_NAME='" + '' + "'\nORIENTATION='" + direction + "'\n" + editorValue
        setPreviousCode(editorValue)
        setVisibleLoading(false)

        generateArchitectureDiagram(tempData, isDownload, function (resp) {
          setVisibleLoading(false)
        })
      } else if (direction !== localStorage.getItem('ORIENTATIONS')) {
        let orientation = 'TB'
        if (localStorage.getItem('ORIENTATIONS')) {
          orientation = localStorage.getItem('ORIENTATIONS')
        }
        let tempData = editorValue
        tempData = "DIAGRAM_NAME='" + '' + "'\nORIENTATION='" + direction + "'\n" + editorValue
        setPreviousCode(editorValue)
        setVisibleLoading(false)
        generateArchitectureDiagram(tempData, isDownload, function (resp) {
          setVisibleLoading(false)
        })
      }
    }
    
    if (initial) {
      generate()
      setInitial(false)
    }

    if (!initial) {
      const intervalId = setInterval(generate, 3000)
      return () => clearInterval(intervalId)
    }
    localStorage.setItem('selectedMode', CONSTANTS.ARCHITECTURE_DIAGRAM)
  }, [editorValue, previousCode, initial, diagramName, direction])

  return (
    <div className="alignMiddle" style={{ overflow: 'auto' }}>
      {loading && (
        <div className="alignMiddle">
          <CircularProgress />
          Executing...
        </div>
      )}
      <MapContainer
        center={[51.505, -0.09]}
        zoom={13}
        attributionControl={false}
        zoomControl={false}
      >
        <PanZoom ref={panZoomRef}>
          <div className="alignMiddle">
          {!loading && (imageSrc ? (
              <img src={imageSrc} alt="Architecture Diagram" className="image-style" />
            ) : (
              <div style={{ color: 'gray', fontSize: '24px' }}></div>
            ))}
          </div> 
        </PanZoom>
      </MapContainer>
    </div>
  )
}

export default ArchitectureDiagram
