const iconCodeInitialState = ''

const iconCodeReducer = (state = iconCodeInitialState, action) => {
  if (action.type === 'CLICK') {
    if(action.payload.includes("REPLACEME")){
      action.payload=action.payload.replace("REPLACEME", "");
    }
    return action.payload
  }
  return state
}

export default iconCodeReducer
