import { CONSTANTS } from 'src/components/Constants'
import { SET_SELECTED_MODE_VALUE } from '../actions/StateAction'
import { SET_RUN_TOUR } from '../actions/StateAction'

const localStorageKey = "selectedMode"
const storedSelectedModeValue = localStorage.getItem(localStorageKey)
const defaultSelectedModeValue = storedSelectedModeValue || CONSTANTS.FLOW_CHART
const localStorageKeyforRunTour = "shouldSeeTour"
const storedRunTourValue = localStorage.getItem(localStorageKeyforRunTour)

const initialState = {
  selectModeValue: storedSelectedModeValue || defaultSelectedModeValue,
  setRunTour: storedRunTourValue !== 'false',
}

const stateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_MODE_VALUE:
      return {
        ...state,
        selectModeValue: action.payload,
      }
    case SET_RUN_TOUR:
      return {
        ...state,
        setRunTour: action.payload,
      }
    default:
      return state
  }
}

export default stateReducer
