import React from "react";
import '.././scss/Landing.css'

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  RedditShareButton,
  EmailShareButton,
  EmailIcon,
  RedditIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";
import { Tooltip } from "@mui/material";

const SocialMediaShare = () => {
  const shareUrl = "https://code2diagram.com/";
  const header = "Code 2 Diagram";

  return (
    <div className="share_buttons">
      {/* <Tooltip title="Facebook">
        <FacebookShareButton url={shareUrl} className="icon_button">
          <FacebookIcon size={30} round={true} />
        </FacebookShareButton>
      </Tooltip> */}
      <Tooltip title="Twitter">
        <TwitterShareButton url={shareUrl} className="icon_button">
          <TwitterIcon size={30} round={true} />
        </TwitterShareButton>
      </Tooltip>
      <Tooltip title="LinkedIn">
        <LinkedinShareButton  url="https://www.linkedin.com/company/code2diagram?trk=public_post_feed-actor-name" onClick={() =>
                (window.location.href = 'https://www.linkedin.com/company/code2diagram?trk=public_post_feed-actor-name')
              } className="icon_button">
          <LinkedinIcon size={30} round={true} />
        </LinkedinShareButton>
      </Tooltip>
      {/* <Tooltip title="Reddit">
        <RedditShareButton url={shareUrl} className="icon_button">
          <RedditIcon size={30} round={true} />
        </RedditShareButton>
      </Tooltip> */}
      <Tooltip title="Email">
        <EmailShareButton url={shareUrl} className="icon_button">
          <EmailIcon size={30} round={true} />
        </EmailShareButton>
      </Tooltip>
    </div>
  );
};

export default SocialMediaShare;
