import { SET_MINDMAP_EDITOR_VALUE } from '../actions/mindmapAction'

const localStorageKey = 'mindmap_diagram'

const storedMindmapEditorValue = localStorage.getItem(localStorageKey)

const defaultMindmapEditorValue =
  storedMindmapEditorValue ||
  'Legacy Updates\n    Master\n        Doe\n        Smith\n    epic-1234\n        Johnson\n            Clark\n        Brown\n    Pontet\n        White\n        Miller'
const initialState = {
  mindmapEditorValue: storedMindmapEditorValue || defaultMindmapEditorValue,
}

const mindmapEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MINDMAP_EDITOR_VALUE:
      return {
        ...state,

        mindmapEditorValue: action.payload,
      }

    default:
      return state
  }
}

export default mindmapEditorReducer
