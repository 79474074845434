import { Box, Button, Modal, Typography } from '@mui/material'
import React from 'react'
import { modalStyle } from 'src/utils/history'

const DeleteModal = ({ showDeleteConfirmation, setShowDeleteConfirmation, handleDelete }) => {
  return (
    <Modal open={showDeleteConfirmation} onClose={() => setShowDeleteConfirmation(false)}>
      <Box sx={modalStyle}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Delete Confirmation
        </Typography>
        <hr/>
        <footer style={{ display: 'flex', gap: '40px', justifyContent: 'flex-end', margin: '15px' }}>
        <Button
            size="small"
            className="cancel-button"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            Cancel
          </Button>
          <Button size="small" className="delete-button" onClick={() => handleDelete()}>
            Delete
          </Button>
         
        </footer>
      </Box>
    </Modal>
  )
}

export default DeleteModal
