import React from "react";
import { Modal, Typography } from "@mui/material";
import '../../scss/Landing.css'

const IncompatibleDevice = ({
  showIncompatibleDevice,
  setShowIncompatibleDevice,
}) => {
  return (
    <Modal
      open={showIncompatibleDevice}
      onClose={() => setShowIncompatibleDevice(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className='incompatible_modal_content'>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mt: 2, color: "white" }}
        >
          Incompatible Device
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, color: "white" }}>
          This application may not be fully compatible with your device. For the
          best experience, please use a desktop browser or a device with a
          larger screen.
        </Typography>
      </div>
    </Modal>
  );
};

export default IncompatibleDevice;
