import { Box, Button, Modal, TextField, Typography } from '@mui/material'
import React from 'react'
import { modalStyle } from 'src/utils/history'

const SaveModal = ({ visibleSave, setVisibleSave, setFilename, saveCodeApi, filename }) => {
  return (
    <Modal open={visibleSave} onClose={() => setVisibleSave(false)}>
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Save File
        </Typography>
        <hr />
        <div>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}
          >
            <span>Please enter a new name for the file</span>
            <br />
          </Typography>
          <TextField
            size="small"
            hiddenLabel
            id="filled-hidden-label-small"
            placeholder="File Name"
            autoComplete="off"
            onChange={(e) => {
              setFilename(e.target.value)
            }}
            sx={{ width: '100%' }} 
          />
        </div>
        <footer style={{ display: 'flex', gap: '5px', justifyContent: 'flex-end', margin: '15px', width: '94%' }}>
          <Button size="small" className="cancel-button" onClick={() => setVisibleSave(false)}>
            Cancel
          </Button>
          <Button
            className="icon-button"
            onClick={() => {
              saveCodeApi(filename)
              setVisibleSave(false)
            }}
          >
            Save
          </Button>
        </footer>
      </Box>
    </Modal>
  )
}

export default SaveModal
