export const SET_AVROSCHEMA_EDITOR_VALUE = "SET_AVROSCHEMA_EDITOR_VALUE";
export const SET_AVROSCHEMA_FILE_VALUE = "SET_AVROSCHEMA_FILE_VALUE";

export const setAvroSchemaEditorValue = (avroSchemaEditorValue) => ({
  type: SET_AVROSCHEMA_EDITOR_VALUE,
  payload: avroSchemaEditorValue,
});

export const setAvroSchemaFileValue = (avroSchemaFileValue) => ({
  type: SET_AVROSCHEMA_FILE_VALUE,
  payload: avroSchemaFileValue,
});