import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PanZoom } from 'react-easy-panzoom'
import axios from 'axios'
import { generateTerraformDiagram } from 'src/views/dashboard/DashboardFunctions'
import { CONSTANTS } from '../Constants'
import { MapContainer } from 'react-leaflet'
import { CircularProgress } from '@mui/material'

const Terraform = ({
  panZoomRef,
  setOpenSnackbar,
  setOpenSnackbarDiagram,
  setSnackbarMessage,
  setSnackbarSeverity,
}) => {
  let editorValue = useSelector((state) => state.terraformEditor.terraformEditorValue)
  const [terraformImage, setTerraformImage] = useState('')
  const [loading, setLoading] = useState(true)
  const [previousCode, setPreviousCode] = useState('')
  const [initial, setInitial] = useState(true)

  axios.defaults.withCredentials = true

  useEffect(() => {
    if (panZoomRef.current) {
      panZoomRef.current.setOptions({ preventScroll: true });
    }
  }, [panZoomRef]);

  useEffect(() => {
    const generateTerraformImage = async () => {
      if(!editorValue.trim()){
        setTerraformImage('')
        setPreviousCode('')
        return
      }
      try {
        if (previousCode.trim() === editorValue.trim()) return
        setPreviousCode(editorValue)
        setOpenSnackbarDiagram(true)
        const outputImage = await generateTerraformDiagram(editorValue)
        if (outputImage != null && !outputImage.includes('failed to compile')) {
          setTerraformImage(outputImage)
          localStorage.setItem('TERRAFORM_IMG', outputImage)
          setLoading(false)
          setOpenSnackbarDiagram(false)
        } else {
          setLoading(false)
          setOpenSnackbar(true)
          setSnackbarMessage('Compilation failed. Invalid input format')
          setSnackbarSeverity('error')
          setOpenSnackbarDiagram(false)
        }
      } catch (error) {
        console.error('Error generating terraform diagram:', error)
        setLoading(false)
        setOpenSnackbar(true)
        setSnackbarMessage('Compilation failed. Invalid input format')
        setSnackbarSeverity('error')
      }
    }
    if (initial) {
      generateTerraformImage()
      setInitial(false)
    }

    if (!initial) {
      const intervalId = setInterval(generateTerraformImage, 3000)
      return () => clearInterval(intervalId)
    }
    localStorage.setItem('selectedMode', CONSTANTS.TERRAFORM)
  }, [editorValue, previousCode, initial])

  return (
    <div className="alignMiddle" style={{ overflow: 'auto' }}>
      {loading && (
        <div className="alignMiddle">
          <CircularProgress />
          Executing...
        </div>
      )}
      <MapContainer
        center={[51.505, -0.09]}
        zoom={13}
        attributionControl={false}
        zoomControl={false}
      >
        <PanZoom ref={panZoomRef}>
          <div className="alignMiddle">
          {!loading && (terraformImage? (
              <img
                src={`data:image/png+xml;base64,${terraformImage}`}
                alt="Terraform Diagram"
                className="image-style"
              />
            ) : (
              <div style={{ color: 'gray', fontSize: '24px' }}></div>
            ))}
          </div>
        </PanZoom>
      </MapContainer>
    </div>
  )
}
export default Terraform
