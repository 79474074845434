import { SET_CLASS_EDITOR_VALUE } from '../actions/classEditorActions';

const localStorageKey = 'class_diagram';

const storedClassEditorValue = localStorage.getItem(localStorageKey);
const defaultClassEditorValue =
  storedClassEditorValue ||
  ' Animal <-- Duck \n Animal <-- Fish \n Animal <--> Zebra \n Animal : int age \n Animal : String gender \n Animal: isMammal() \n Animal: mate() \n class Duck{  \n  String beakColor \n swim() \n quack() \n } \n class Fish {\n  int sizeInFeet \n  canEat() \n } \n class Zebra{ \n bool is_wild \n run() \n }'

const initialState = {
  classEditorValue: storedClassEditorValue || defaultClassEditorValue,
};

const classEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLASS_EDITOR_VALUE:
      return {
        ...state,
        classEditorValue: action.payload,
      };
    default:
      return state;
  }
};

export default classEditorReducer;
