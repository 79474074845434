import axios from "axios";
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {
    CCardBody, CListGroup, CListGroupItem,
    CContainer,
    CRow,
    CCol,
    CAlert,
    CModal,
    CModalHeader,
    CModalTitle,
    CModalBody,
    CModalFooter,
    CSpinner,
} from "@coreui/react";
import '../../scss/_custom.scss';
import { COLORS } from "src/components/Colors";
import { Button } from "@mui/material";

export const SubscriptionComponent = () => {
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [error, setError] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const { user, getAccessTokenSilently } = useAuth0();
    const [status, setStatus] = useState("STATUS");
    const [amount, setAmount] = useState("0 USD");
    const [showSpinner, setShowSpinner] = useState(true);
    const [subscriptionId, setSubscriptionId] = useState("SUBSCRIPRION_ID");
    const [nextBillingDate, setNextBillingDate] = useState("NEXT_BILLING_DATE");

    axios.defaults.withCredentials = true;

    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently();
            axios
                .get(`${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/stripe/subscription/get?subscriptionId=` + sessionStorage.getItem("subscription_id"), {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    setShowSpinner(false)
                    if (response.data.result) {
                        const result = response.data.result;
                        var payload = JSON.parse(result.payload);
                        setStatus(result.status);
                        setAmount(payload.items.data[0].plan.amount / 100 + " USD")
                        setSubscriptionId(result.subscription_id);
                        setNextBillingDate(result.nextBillingDate.split("T")[0]);
                    }
                })
                .catch((err) => {
                    setShowSpinner(false)
                    console.log("Error occurred " + err.msg);
                })
        })();

    }, []);

    async function cancelSubscription() {
        setVisible(false);
        const token = await getAccessTokenSilently();

        const data = {
            subscriptionId: sessionStorage.getItem("subscription_id")
        };
        axios
            .post(`${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/stripe/subscription/cancel`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then((response) => {
                if (response.data.result === "cancelled") {
                    setShowAlert(true);
                } else {
                    setError(true);
                }
            })
            .catch((err) => {
                setError(true);
                console.log("Error occurred " + err.msg);
            })
    }

    return (
        <div style={{ backgroundColor: "#000000", minHeight: "100vh", color: "#ffffff" }}>
            {showSpinner && (
                <div className="d-flex justify-content-center" style={{ paddingTop: '100px' }}>
                    <CSpinner color='info' size="sm" style={{ width: '30rem', height: '30rem' }} />
                </div>
            )}

            {!showSpinner && (
                <CContainer sm>
                    <CRow xs={{ gutterX: 5 }}>
                        <CCol>

                            <br />
                            <div className="p-3 border" style={{ color: "#212121", backgroundColor: COLORS.PRIMARY_LIGHT }}>
                                <CAlert color="primary" dismissible visible={showAlert} onClose={() => setShowAlert(false)}>
                                    Subscription Cancelled Successfully
                                </CAlert>
                                <CAlert color="danger" dismissible visible={error} onClose={() => setError(false)}>
                                    Internal Server Error
                                </CAlert>
                                < br />
                                <Button style={{ backgroundColor: COLORS.PRIMARY_DARK }} size="small" onClick={() => navigate(-1)}>
                                    Back
                                </Button>

                                <br /> 
                                <CRow className="align-items-center profile-header mb-5 text-center text-md-left">
                                    <CCol md>
                                        <h2>Subscription Details</h2>
                                    </CCol>
                                </CRow>

                                <CModal visible={visible} onClose={() => setVisible(false)}>
                                    <CModalHeader>
                                        <CModalTitle>Confirm Cancel</CModalTitle>
                                    </CModalHeader>
                                    <CModalBody>
                                        <p>Are You Sure To Cancel Subscription.</p>
                                    </CModalBody>
                                    <CModalFooter>
                                        <Button color="secondary" onClick={() => setVisible(false)}>Close</Button>
                                        <Button style={{ color: COLORS.WHITE, backgroundColor: COLORS.CALL_TO_ACTION }} onClick={() => cancelSubscription()}>Cancel</Button>
                                    </CModalFooter>
                                </CModal>

                                <CCardBody style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <CRow>
                                        <CCol sm="24" lg="12">
                                            <CListGroup>
                                                <CListGroupItem>
                                                    <strong>Subscription ID:</strong> {subscriptionId}
                                                </CListGroupItem>
                                                <CListGroupItem>
                                                    <strong>Status:</strong> {status}
                                                </CListGroupItem>
                                            </CListGroup>
                                        </CCol>
                                        <CCol sm="24" lg="12">
                                            <CListGroup>
                                                <CListGroupItem>
                                                    <strong>Amount:</strong> {amount}
                                                </CListGroupItem>
                                                <CListGroupItem>
                                                    <strong>Email:</strong> {user.email}
                                                </CListGroupItem>
                                                <CListGroupItem>
                                                    <strong>Next Billing Date:</strong> {nextBillingDate}
                                                </CListGroupItem>
                                            </CListGroup>
                                        </CCol>
                                    </CRow>
                                </CCardBody>
                                <br /> 
                                {status === 'active' && (
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                        <Button style={{ color: COLORS.WHITE, backgroundColor: COLORS.CALL_TO_ACTION }} size="large" onClick={() => setVisible(!visible)}>
                                            Cancel Subscription
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </CCol>
                    </CRow>
                </CContainer>
            )}

        </div>
    );
};

export default withAuthenticationRequired(SubscriptionComponent, {
});
