import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import * as cola from 'webcola';
import { useSelector } from 'react-redux';
import PanZoom from 'react-easy-panzoom';
import { CONSTANTS } from '../Constants';

const prepareData = (data) => {
  const { nodes: initialNodes, relationships: initialLinks } = data;

  const links = initialLinks.reduce((acc, link) => {
    const source = initialNodes.findIndex((n) => n.id === link.fromNode);
    const target = initialNodes.findIndex((n) => n.id === link.toNode);

    return source !== -1 && target !== -1
      ? [...acc, { ...link, source, target, type: link.type || 'CONTROL' }]
      : acc;
  }, []);

  const nodes = initialNodes.map((node) => {
    const label = node.originalLabel;

    return {
      ...node,
      label,
      width: Math.max(label.length * 10, 30) + 20,
      height: 40,
    };
  });

  return { nodes, links };
};

function parseYaml(yamlData) {
  const lines = yamlData.split('\n');
  let currentParent = null;
  let level = -1;
  const parentAndChild = new Map();
  const intendToParent = new Map();
  const nodeIdMap = new Map();
  const uniqueIdMap = new Map();
  const uniqueNameIndentationMap = new Map();

  let uniqueId = 0;

  for (const line of lines) {
    if (line.trim().length < 1) {
      continue;
    }
    const indentation = line.search(/\S/);

    const name = line.trim();
    const uniqueName = nodeIdMap.has(name) ? `${name}_${uniqueId++}` : name;
    nodeIdMap.set(uniqueName, name); // Store the original name with the unique ID
    uniqueIdMap.set(name, (uniqueIdMap.get(name) || 0) + 1);
    uniqueNameIndentationMap.set(uniqueName, indentation + 1);
    if (level === -1) {
      level = indentation;
      parentAndChild.set(uniqueName, []);
      intendToParent.set(indentation, null);
      currentParent = uniqueName;
    } else if (indentation > level) {
      level = indentation;
      parentAndChild.set(uniqueName, []);
      parentAndChild.get(currentParent).push(uniqueName);
      intendToParent.set(indentation, currentParent);
      currentParent = uniqueName;
    } else if (indentation === level) {
      let parent = intendToParent.get(indentation);
      level = indentation;
      parentAndChild.set(uniqueName, []);
      parentAndChild.get(parent).push(uniqueName);
      intendToParent.set(indentation, parent);
      currentParent = uniqueName;
    } else {
      let parent = intendToParent.get(indentation);
      level = indentation;
      parentAndChild.set(uniqueName, []);
      parentAndChild.get(parent).push(uniqueName);
      intendToParent.set(indentation, parent);
      currentParent = uniqueName;
    }
  }

  let nodes = [];
  let relationships = [];
  for (const [uniqueName, originalName] of nodeIdMap.entries()) {
    let node = { id: uniqueName, originalLabel: originalName, siren: uniqueNameIndentationMap.get(uniqueName) };
    nodes.push(node);
    let children = parentAndChild.get(uniqueName);
    for (let child of children) {
      let rel = { toNode: uniqueName, fromNode: child };
      relationships.push(rel);
    }
  }

  let response = {
    nodes: nodes,
    relationships: relationships,
  };
  return response;
}

function generateGraph(yamlData, graphRef, width, height) {
  const hierarchyTree = parseYaml(yamlData);
  const { nodes, links } = prepareData(hierarchyTree);
  const sirenValues = Array.from(new Set(nodes.map((node) => node.siren)));
  const colorScale = d3.scaleOrdinal(d3.schemeSet1).domain(sirenValues);
  const graph = cola.d3adaptor(d3).linkDistance(200).avoidOverlaps(true).size([width, height]);

  const svg = d3
    .select(graphRef.current)
    .append('svg')
    .attr('width', '100%')
    .attr('height', '100%')
    .attr('viewBox', `0 0 ${width} ${height}`)
    .attr('preserveAspectRatio', 'xMidYMid meet'); // Ensure proper scaling

  svg
    .append('defs')
    .append('marker')
    .attr('viewBox', '0 0 8 12')
    .attr('refX', '0')
    .attr('refY', '6')
    .attr('markerWidth', '4')
    .attr('markerHeight', '6')
    .attr('orient', 'auto')
    .attr('overflow', 'visible')
    .append('polygon')
    .attr('points', '0,0 8,6 0,12')
    .attr('fill', 'white');

  graph.nodes(nodes).links(links).start();

  const link = svg
    .selectAll('.link')
    .data(links)
    .enter()
    .append('path')
    .attr('class', (d) => `link link--${d.type.toLowerCase()}`)
    .style('stroke', 'grey')
    .style('stroke-width', '2px');

  const node = svg
    .selectAll('.node')
    .data(nodes)
    .enter()
    .append('rect')
    .attr('class', 'node')
    .attr('width', (d) => d.width)
    .attr('height', (d) => d.height)
    .attr('rx', 20)
    .attr('ry', 20)
    .style('fill', (d, i) => colorScale(d.siren));

  const label = svg
    .selectAll('.label')
    .data(nodes)
    .enter()
    .append('text')
    .attr('class', 'label')
    .text((d) => d.label)
    .style('fill', 'white')
    .style('text-anchor', 'middle')
    .style('font-size', '16px');

  graph.on('tick', function () {
    node.attr('x', (d) => d.x - d.width / 2).attr('y', (d) => d.y - d.height / 2);

    link.attr(
      'd',
      ({ source, target }) =>
        `M ${source.x} ${source.y}
         L ${target.x} ${target.y}`
    );

    label
      .attr('x', (d) => d.x)
      .attr('y', function (d) {
        var h = this.getBBox().height;
        return d.y + h / 4;
      });
  });


  // Serialize SVG and store in local storage
  const svgElement = d3.select(graphRef.current).select('svg').node(); // Get the raw DOM element
  const serializer = new XMLSerializer();
  const svgString = serializer.serializeToString(svgElement);

  // Convert to Base64
  const base64EncodedSVG = btoa(svgString);
  localStorage.setItem("MINDMAP_IMG", base64EncodedSVG)
}

const Mindmap = ({ panZoomRef, setOpenSnackbar, setSnackbarMessage, setSnackbarSeverity }) => {
  const yamlData = useSelector((state) => state.mindmapEditor.mindmapEditorValue);
  const graphRef = useRef(null);

  useEffect(() => {
    d3.select(graphRef.current).select('svg').remove(); // Clear the diagram

    if (!yamlData.trim()) {
      // Show placeholder text if yamlData is empty
      d3.select(graphRef.current)
        .append('svg')
        .attr('width', '100%')
        .attr('height', '100%')
        .append('text')
        .attr('x', '50%')
        .attr('y', '50%')
        .attr('dy', '.35em')
        .attr('text-anchor', 'middle')
        .attr('font-size', '24px')
        .attr('fill', 'gray')
        .text('');
      return;
    }

    try {
      const hierarchyTree = parseYaml(yamlData);
      const { nodes } = prepareData(hierarchyTree);
      const width = 140 * nodes.length;
      const height = 140 * nodes.length;
      generateGraph(yamlData, graphRef, width, height);
      localStorage.setItem('selectedMode', CONSTANTS.MINDMAP);
    } catch (err) {
      setSnackbarMessage('Invalid input format');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      console.log('error', err);
    }
  }, [yamlData]);

  return (
    <div className="alignMiddle" style={{ overflow: 'auto' }}>
      <PanZoom minZoom={1} ref={panZoomRef}>
        <div id="mindMapId" ref={graphRef} className="image-style" />
      </PanZoom>
    </div>
  );
};

export default Mindmap;
